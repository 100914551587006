const trips = {
  coastToCoast: {
    code: "coastToCoast",
    name: "The Coast to Coast Trip",
    headerSrc: require("@/assets/trips/ctoc/header.jpg"),
    overview: {
      length: "12 days",
      mapSrc: require("@/assets/trips/ctoc/map.jpg"),
      sellText:
        "Hike, bike, raft and sea kayak 248 kilometers from Pacific to Atlantic - cross all of Costa Rica using only your muscles, sweat, stamina, and sense of humor.",
      overviewText: [
        "The only tour with a completely human-powered 248 kilometer (154 mile) crossing of Central America. The Coast to Coast Adventure is our flagship trip, challenging people since 1994. A true multi-activity eco adventure - mountain biking, hiking, white water rafting, sea kayaking, plus playing under waterfalls and swimming in crystalline rivers, always progressing with your own muscles towards your goal of the east coast of the continent. It's all up to you - no motors or engines or animal packs to help.",
        "The route traverses mountain villages, coffee and sugar plantations, riverside communities and small villages remote from pavement and traffic. You cross the Continental Divide and descend into virgin forests, camping and cooking under the stars.",
        "Classified as HARD in our scale of difficulty, but the main requirement is a sense of adventure and a good dose of stamina.",
      ],
      promotionText:
        "Book 4 people on any of our set departure dates and get 10% off",
      highlights: [
        "Crossing Costa Rica under your own power.",
        "Sandy beaches of the Manuel Antonio National Park.",
        "Mountain bike and trek up and over the Continental Divide.",
        "Biking through the coffee covered valley of Orosi.",
        "Whitewater rafting down the Pacuare River.",
        "Overnight at rainforest tent camp - El Nido del Tigre.",
        "Paddling a river until it ends on a Caribbean beach.",
        "Beaches, surf, and wildlife of Cahuita National Park.",
      ],
      tripDates: [
        {
          year: 2024,
          dates: [
            "Mar 2 - 13",
            "Apr 6 - 17",
            "Apr 27 - May 8",
            "Aug 5 - 16",
            "Aug 31 - 11",
            "Nov 9 - 20",
            "*Dec 19 - 30",
          ],
        },
        {
          year: 2025,
          dates: [
            "Jan 18 - 29",
            "Feb 1 - 12",
            "Mar 1 - 12",
            "Mar 29 - Apr 9",
            "Apr 26 - May 07",
            "Aug 30 - 10",
            "Nov 08 - 19",
            "*Dec 19 - 30",
          ],
        },
      ],
      note: "NOTE: All dates are guaranteed departures. We will open a new date with a minimum of 4 people.",
      priceSection: {
        primary: {
          price: "$2,996 USD",
          pp: "per person",
          singleSupp: "Single Supplement $595",
          validity: "(Price valid until Oct. 31, 2024)",
        },
        peakSeason: {
          priceText: "PEAK SEASON",
          price: "$3,470 USD",
          pp: "per person",
          singleSupp: "Single Supplement $760",
          validity: "(Christmas 2024)",
        },
        secondary: {
          priceText: "PEAK SEASON",
          price: "$3,116 USD",
          pp: "per person",
          singleSupp: "Single Supplement $680",
          validity: "(Nov. 1, 2024 to Oct. 31, 2025)",
        },
      },
    },
    itinerary: [
      {
        day: 1,
        logos: ["arrival"],
        title: "Arrival in Costa Rica – Hotel in San Jose",
        content: [
          "Your Tour Guide will meet your flight and drive to your overnight accommodation in San Jose.",
        ],
      },
      {
        day: 2,
        logos: ["pacific-palm"],
        title: "Quepos / warm up day – Manuel Antonio National Park",
        content: [
          "Our challenge starts on the Pacific Coast. We leave San José by bus (your last motorized vehicle for the next 10 days) to the fishing village of Quepos, next to Manuel Antonio National Park. Relax, chill out on the beach or visit the National Park, famous for its abundant wildlife. We have set aside time before dinner to check out and set up bikes as it is an early start tomorrow morning. The official trip briefing is after dinner this evening. (B, D)",
        ],
      },
      {
        day: 3,
        logos: ["biking", "trekking"],
        title:
          "Naranjillo – Photos on the beach and we’re off! Biking 19km/12mi + Trekking 14km/9mi",
        content: [
          "THE ADVENTURE BEGINS... We awake early and enjoy a light buffet of fresh fruit and coffee then set off on bikes to climb the steep mountains of the sparsely populated Pacific Coast. The vista from above is your reward for hard work. In the small town of Esquipulas we eat a hearty breakfast, rest up and prepare our day packs for the next leg of the day’s journey. Trading our wheels for hiking boots, we continue by trekking into the cool mountains of Central Costa Rica. We camp overnight in the valley of Naranjillo. (B, L, D)",
        ],
      },
      {
        day: 4,
        logos: ["trekking", "biking"],
        title:
          "Santa Maria – Pacific slopes Trekking 12km/7mi + Biking 14km/9mi",
        content: [
          "Our day begins with a trek towards the Continental Divide and challenging the infamous “Cemetery Hill” - a strenuous 13 kilometer climb. We then head for the pueblo of San Lorenzo, switching to mountain bikes to reach the quaint village of Santa Maria where we refortify with some typical Costa Rican fare and rest up in comfortable local cabins. (B, L, D)",
        ],
      },
      {
        day: 5,
        logos: ["biking"],
        title:
          "Orosi Valley – Up and over the Continental Divide – Biking 49km/30mi",
        content: [
          "Today we tackle the hardest, steepest climb of our adventure. The challenge before breakfast is mountain biking a 14-kilometer up-hill stretch to the Continental Divide, bringing us to an altitude of 2,330 meters - the highest peak of our journey. Following the rule that everything that goes up must come down, the remainder of the day is mountain biking at its best - mostly downhill on dirt roads and single-track trails. We finish the afternoon in the beautiful Orosi Valley where rich pastures and dark green forests of coffee carpet the land and we rest for the night in the town of Orosi. (B, L)",
        ],
      },
      {
        day: 6,
        logos: ["biking", "trekking"],
        title:
          "Humo – Through the valley to the Tapanti area Biking 9km/6mi + Trekking 17km/10.5mi",
        content: [
          "After breakfast, we mountain bike along a river to the Tapantí National Wildlife Refuge. This is an area of vast rugged terrain covered with dense primary rainforest. Leaving our bikes, we take to the mountains trekking amongst thousands of trees covered with ferns, bromeliads, mosses and other epiphytes... a primary cloud forest ecology. The end of the day finds us on flatter ground, camping near the small settlement of Humo. (B, L, D)",
        ],
      },
      {
        day: 7,
        logos: ["biking", "rafting"],
        title: "Pacuare River – Biking 43km/27mi + Rafting 10km/6mi",
        content: [
          "After breakfast we continue mountain biking towards the whitewater rafting “put­in” at La Cruz,the upper gateway to the raging Pacuare River. We eat lunch then load up into the rafts to paddle and PADDLE through pristine rainforest, canyon cliffs, spotted with deafening waterfalls. By afternoon we arrive at our comfortable riverside camp, El Nido del Tigre, nestled between the sounds of the rainforest and the hum of the river. You can wander off to explore this lush environment, looking out for toucans and boa constrictors and sloth, or simply relax in a hammock to the sounds of the river. (B, L, D)",
        ],
      },
      {
        day: 8,
        logos: ["trekking"],
        title:
          "Pacuare Valley – Free day in the rainforest - hike to waterfall, play in a rapid, and relax in a hammock",
        content: [
          "We spend some quality time at this unique location on the banks of the Pacuare River, hiking the hills, exploring primary forest, swimming in the Pacuare, exploring waterfalls and swimming holes with water so pure you can drink it (most of the year). This area of rainforest remains in a very natural state, with minimum disruption to accommodate our guests. El Nido del Tigre is the name of the giant ginger plants carpeting the area around the campsite. It's a very private place, with small numbers of guests, so the sense of purity and nature seep into your dreams at night. (B, L, D)",
        ],
      },
      {
        day: 9,
        logos: ["rafting"],
        title: "Pacuare River – Rafting 20km/12mi",
        content: [
          "Today the Pacuare takes us through virgin rainforest, cascading waterfalls and river carved canyons. We maneuver through the exhilarating rapids and in gentler sections you can see into the rainforest at the river’s edge. Along the way we take time out to do some hiking to spectacular waterfalls and have lunch on the shore. At last, in the late afternoon, we take-out at the beginning of the banana plantations at Finca Pacuarito, then rest for the night. (B, L, D)",
        ],
      },
      {
        day: 10,
        logos: ["biking", "kayaking"],
        title: "Caribbean coast – Biking 26km/16mi + Kayaking 12km/7mi",
        content: [
          "THE SWEET TASTE OF SUCCESS...This morning we jump on our bikes for a short ride through banana plantations to the town of Freeman where we begin the final leg of the traverse in kayaks down the Pacuare leaving the rapids behind as the river eases into the Caribbean lowlands where banana plantations gradually swallow the receding rainforest. The river slows to a tranquil pace as we are rafting the last mile of the river, completing the mosaic of the landscapes we have experienced. At the end of the adventure, we reach the mouth of the Pacuare as it meets the Caribbean Sea, celebrating our passage with bubbly in the surf, while enjoying a well-deserved rest. After some time on the beach we are picked up by a motorboat and transferred to Moin from where we travel by vehicle to the beach. (B, L)",
        ],
      },
      {
        day: 11,
        logos: ["caribbean-palm"],
        title:
          "Caribbean coast – Free morning / Afternoon transfer back to San Jose",
        content: [
          "The morning is free to relax around the hotel or on the beach. After lunch we head back to San José, about 4 hours away. You will gasp at the beauty of breathtaking views as we pass through high cloud forest, misty mountains, deep, steep, river-carved canyons, the spectacular Braulio Carrillo National Park. (B)",
        ],
      },
      {
        day: 12,
        logos: ["departure"],
        title: "Departure",
        content: [
          "Coast to Coast will pick you up for your trip to the airport. So sad it had to end, so good you got to live it.... (B)",
        ],
      },
    ],
    needToKnow: [
      {
        title: "General Trip Information",
        sections: [
          {
            title: "Is this trip for me?",
            content: [
              "We divide our trips in 3 different levels (low ,medium and high) to give our participants an idea of the fitness level they should have for the trip. This trip is rated as High.",
              "High: You need to be reasonably fit and have good stamina as the trip is a challenge. Good understanding and experience of mountain biking will make the trip more enjoyable. Experience of whitewater rafting is not necessary but you will be paddling all the time you are on the raft so fitness and stamina are a must.",
            ],
          },
          {
            title: "Daily routine",
            content: [
              "Our days usually start with fresh coffee and breakfast around 6:30. All packed and ready to go for our activity by 8:00. During the day you will have lots of time for pictures, water breaks and enjoying the scenery. The activities will be done at a comfortable pace for everyone, set by the guide. Around mid-day we will stop for lunch at a local restaurant or a picnic lunch made by the guides. The group will finish the day around 3:00 with plenty of time to rest. Dinner is usually served around 7:00 followed by a briefing for the next day.",
            ],
          },
          {
            title: "Price includes",
            content: [
              "Accommodation, transportation, airport transfers, meals where indicated, group equipment (bikes, rafts, camping gear, etc.), support vehicle and experienced, bilingual & fun guides throughout the trip.",
            ],
          },
          {
            title: "Group leader",
            content: [
              "Our Tour Leaders are a key part of any CtoC trip. All expertly trained and experienced in every field of activity, bilingual and qualified in first aid and/or Wilderness First responder. They are passion for what they do and full with knowledge ready to share with you. They are responsible for ensuring the complete safety, well-being and enjoyment of our guests but retain a flexible and fun attitude.",
            ],
          },
          {
            title: "Guides Qualifications",
            content: [
              "All Coast to Coast Adventures’ guides have First AID, CPR and Swiftwater Rescue and Head Guides have Wilderness First Responder.",
            ],
          },
          {
            title: "Group size",
            content: ["Average between 6 and 12."],
          },
          {
            title: "Bikes Specifications",
            content: [
              "29ers Mountain Bike (Scott and Trek) or similar.",
              "Every bike has front suspension and disk brakes. Participants can bring their own pedals, seats and/or accessories if they want.",
            ],
          },
          {
            title: "Vaccinations & Protection",
            content: [
              "Vaccination requirements are subject to change and should be confirmed before departure. Consult your Doctor for latest advice on different prophylaxis available against malaria.",
            ],
          },
          {
            title: "Visas",
            content: [
              "All countries require a valid passport (with a minimum 6 months validity). Contact your local embassy, or consulate for the most up-to-date visa requirements. IT'S YOUR RESPONSIBILITY TO HAVE THE CORRECT TRAVEL DOCUMENTATION.",
            ],
          },
          {
            title: "Laundry",
            content: [
              "Having your cloth clean and dry while you travel is always a challenge. Laundry services are offered by some of our hotels for a charge. There will be times when you may want to do your own laundry so we suggest you bring non-polluting/biodegradable soap.",
            ],
          },
          {
            title: "Travel Insurance",
            content: [
              "CtoC Adventures requires all customers to have valid Travel Insurance.",
            ],
          },
        ],
      },
      {
        title: "Accommodations, Meals and Transportation",
        sections: [
          {
            title: "Accommodations",
            content: [
              "5 nights in Hotels",
              "5 nights in campsites",
              "1 night in local cabin",
            ],
          },
          {
            title: "Transportation",
            content: [
              "All transportation according to the itinerary is included, starting with an airport transfer on the first day, transfer San Jose to Quepos, motor boat from the finish to Moin, transfer Cahuita to San Jose, support vehicle from one area to another during the trip, finishing with the transfer out to the airport.",
            ],
          },
          {
            title: "Meals",
            content: [
              "Eating is one of the wonderful experiences when travelling in Costa Rica. Included meals are a combination of restaurants, local food and delicious meals prepared by the tour guides.",
            ],
          },
          {
            title: "Meals Included",
            content: ["Breakfasts 12", "Lunches 8", "Dinners 7"],
          },
        ],
      },
      {
        title: "Gear / Kit Needed",
        sections: [
          {
            title: "Luggage Carrier",
            content: [
              "Duffle Bag",
              "Daypack (approx. 20-35 L)",
              "Daypack rain cover",
            ],
          },
          {
            title: "Upper Body",
            content: [
              "Shirts (poly-pro outer wear (for quick drying)",
              "Short sleeve and light colored long sleeve for sun protection)",
              "Lightweight rain jacket (Gortex, nylon or similar)",
              "Medium weight wool or fleece over-sweater",
              "Lightweight thermal underwear top (polypro/wool)",
            ],
          },
          {
            title: "Lower Body",
            content: [
              "Lightweight comfortable pants",
              "Shorts (quick dry)",
              "Lightweight rain pants",
            ],
          },
          {
            title: "Head",
            content: [
              "Sun/rain hat with brim (preferably 360˚ brim)",
              "Bandana",
            ],
          },
          {
            title: "Footwear",
            content: [
              "Lightweight adventure shoes or hiking boots",
              "Sandals with SNUG straps (Teva type)",
              "Lightweight fast drying hiking socks",
            ],
          },
          {
            title: "Personal Gear",
            content: [
              "Sunglasses (UVA and UVB resistant)",
              "Keeper straps for sunglasses",
              "1 headlamp (long lasting LED bulbs are the best option)",
              "Extra batteries (for headlamp, camera, etc)",
              "Additional small flashlight",
              "2-3 one quart, durable water bottles",
              "Prescription glasses or contacts (bring an extra pair of glasses)",
              "1 copy of your passport",
              "Insect repellent",
              "Waterproof sunscreen 30+ SPF",
              "Gallon & quart size Ziploc bags",
              "Towel",
            ],
          },
          {
            title: "Optional Gear",
            content: [
              "Dry bag",
              "Camera w/ waterproof camera bag",
              "Deck of cards",
              "Binoculars",
              "Sarongs (works as a towel, or quick cover-up)",
              "Writing materials (pen, pencil, notebook…)",
              "USB memory card (to share pictures with other participants)",
              "Trekking poles",
            ],
          },
          {
            title: "Biking",
            content: [
              "Bike shorts with padded seat",
              "Bike water bottle",
              "Bike helmet",
              "Bike gloves (can also be used for paddling)",
              "Bike shirts (optional)",
            ],
          },
          {
            title: "Note",
            content: [
              "No sleeping gear needed for overnight stays at El Nido del Tigre and Finca Pacuarito",
              "Feel free to bring your own pedals and seat if you want",
              "We supply the basic equipment needed for the activities. We asked our customers to bring their own cycling helmet.",
            ],
          },
        ],
      },
      {
        title: "Money Related",
        sections: [
          {
            title: "Money Exchange",
            content: [
              "A combination of US dollars and credit/debit cards is ideal, it’s best to bring notes in good condition and lower than 100USD. Travelers cheques are not a good idea. Cards are widely accepted but little stores or street sellers may only take local cash. There are ATMS along the way. Check with your bank to make sure your card will work internationally.",
            ],
          },
          {
            title: "Spending Money",
            content: [
              "How much you need will depend on your spending habits. You will need to pay for some meals, drinks, souvenirs and tipping. Here are a few things to keep in mind when you are deciding on the amount of spending money to take on your trip.",
            ],
          },
          {
            title: "Departure Tax",
            content: [
              "The Departure Tax in Costa Rica is $29. This needs to be paid at the airport using US or local cash or any major credit card. Pay this tax before going to the airline counter. Check with your airline if this fee is already included on your flight ticket.",
            ],
          },
          {
            title: "Tipping",
            content: [
              "It is customary in Latin America to tip service providers such as waiters, at approximately 10%, depending on the service. Also if you felt your local guides and drivers did an outstanding job, tipping is appreciated.",
            ],
          },
          {
            title: "Meal Costs",
            content: ["Lunch: $10 to $12", "Dinner: $12 to $20"],
          },
        ],
      },
      {
        title: "Costa Rica General Info",
        sections: [
          {
            title: "Geography",
            content: [
              "We Costa Rica, in Central America is bordered by Nicaragua to the North and Panama to the South with the Pacific Ocean to the West and the Caribbean Sea to the East.",
              "Abolishing its army in 1948, it is the only Latin American country included in the list of the world's 22 older democracies. Costa Rica has consistently been among the top Latin American countries in the Human Development Index, ranked 62nd in the world in 2010, and is cited by the UNDP as one of the countries that has attained much higher human development than other countries at the same income levels. The country is ranked 3rd in the world, and 1st among the Americas, in terms of the 2010 Environmental Performance Index.",
              'In 2007 the Costa Rican government announced plans for Costa Rica to become the first carbon neutral country by 2021. According to the New Economics Foundation, Costa Rica ranks first in the Happy Planet Index and is the "greenest" country in the world.',
              "The highest regions of Costa Rica are found in the center of the country while the lowlands, which are more extensive and flat, extend to the Caribbean Coast on the northeast and to the northwest on the Pacific side. The Pacific Coast is more dissected, forming many bays, capes, cliffs and inlets. The Costa Rican mountain ranges form an independent group, part of the Central American massif. Three of these ranges run roughly from northwest to southeast, with a fourth (The Central Range) crossing them at the widest part of the country, thus forming a huge cross. Two volcanic ranges dominate northwestern Costa Rica. The first is the Guanacaste Volcanic Chain with its Orosi, Miravalles, Tenorio, Rincón de la Vieja and Arenal volcanos. Arenal offers amazing views, especially breathtaking when is clear, while Rincón de la Vieja's activity keeps natural mud pots in its foothills, bubbling permanently. The second range is the Tilarán Volcanic Chain in the northwest, formed by the hills of Abangares, Aguacate and Catedral. In the transverse chain of the central highlands, Poás, Barva, Irazú and Turrialba Volcanos are more accessible. Finally, to the south, are Costa Rica's highest mountains, those of the non-volcanic Talamanca Range. Of these, Chirripó Mountain is the most impressive, being the highest mountain in the country, 3,821 meters above sea level.",
              "The Caribbean coastline stretches for 212 km in a generally north-south direction. It is divided into two distinct sections; the San Juan River on the Nicaraguan border to the city of Limón and that which extends from the city of Limón to the Sixaola River on the border with Panamá. The first section consists of a long stretch of coastline that separates a series of estuary lagoons and waterways, fed by numerous rivers, from the sea. These make up the famous Tortuguero Canals, over 100 km long. These navigable canals are the habitat of seven species of turtles.",
              "The Pacific Coast extends over 1,016 km from one border to the other and offers a wide variety of geological features. From Salinas Bay in the north to the tip of Burica Point in the south, the Pacific Beaches of Costa Rica offer an almost infinite number of beaches for tourist enjoyment.",
              "The Santa Elena, Nicoya and Osa Peninsulas are the principal ones on the Pacific side.",
              "Two ferries cross the Nicoya Gulf, shortening the route to the isolated beaches of the southern tip of the Nicoya Peninsula. The shoreline of the gulf has many bays and promontories and is dotted with small islands.",
              "The Osa Peninsula, covered by great tracts of virgin forest with the most extensive variety of native species to be found in the country, lies on the western side of the Golfo Dulce.",
            ],
          },
        ],
      },
    ],
    photos: [
      require("@/assets/trips/ctoc/ctoc2.jpg"),
      require("@/assets/trips/ctoc/ctoc3.jpg"),
      require("@/assets/trips/ctoc/ctoc6.jpg"),
      require("@/assets/trips/ctoc/ctoc7.jpg"),
      require("@/assets/trips/ctoc/ctoc8.jpg"),
      require("@/assets/trips/ctoc/ctoc4.jpg"),
      require("@/assets/trips/ctoc/ctoc5.jpg"),
    ],
  },
  costaricanAdventure: {
    code: "costaricanAdventure",
    name: "Costa Rican Adventure",
    headerSrc: require("@/assets/trips/costaricanAdventure/header.jpg"),
    overview: {
      length: "8 days",
      mapSrc: require("@/assets/trips/costaricanAdventure/map.jpg"),
      sellText:
        "An ideal trip for those who want a little adventure, beautiful sights and some time to kick back on the beach.",
      overviewText: [
        "Adventure means different things to different people but this is an ideal vacation for everyone. Experience the rainforest and its hidden treasures first hand with an overnight stop at El Nido del Tigre and take the opportunity to whitewater raft in and out of camp on the magnificently picturesque Pacuare River. For those not so inclined, we can arrange for a hike in and out.",
        "Arenal Volcano is seen from different angles while bathing in Hot Springs and hiking to the base of the massive La Fortuna Waterfall. Your individual choice of either cloud forest hiking in Monteverde or the breathtaking view of rainforest meeting sandy beaches in Manuel Antonio offer different challenges.",
      ],
      highlights: [
        "Pacuare River 2 day whitewater rafting adventure.",
        "Overnight in El Nido del Tigre rainforest camp.",
        "Hiking around the magnificent Arenal Volcano.",
        "Sandy beaches and wildlife of the Manuel Antonio National Park, or",
        "Nature in Monteverde cloud forest.",
      ],
      tripDates: [
        {
          year: 2024,
          dates: ["Aug 3 - 10", "Sept 7 - 14", "Nov 9 - 16", "Dec 7 - 14"],
        },
        {
          year: 2025,
          dates: [
            "Jan 4 - 11",
            "Jan 18 - 25",
            "Feb 8 - 15",
            "Mar 8 - 15",
            "Mar 22 - 29",
            "May 24 - 31",
            "Aug 2 - 9",
            "Sept 6 - 13",
            "Nov 8 - 15",
            "*Dec 6 - 13",
          ],
        },
      ],
      note: "NOTE: All dates are guaranteed departures. We will open a new date with a minimum of 4 people.",
      priceSection: {
        primary: {
          price: "$1,298 USD",
          pp: "per person",
          singleSupp: "Single Supplement $365",
          validity: "(Price valid until Oct. 31, 2024)",
        },
        peakSeason: {
          priceText: "PEAK SEASON",
          price: "$1,443 USD",
          pp: "per person",
          singleSupp: "Single Supplement $445",
          validity: "(Eastern 2024)",
        },
        secondary: {
          price: "$1,350 USD",
          pp: "per person",
          singleSupp: "Single Supplement $380",
          validity: "(Nov. 1, 2024 to Oct. 31, 2025)",
        },
      },
    },
    itinerary: [
      {
        day: 1,
        logos: ["arrival"],
        title: "Arrival in Costa Rica – Hotel in San Jose",
        content: [
          "Your Tour Guide will meet your flight and drive to your overnight accommodation in San Jose.",
        ],
      },
      {
        day: 2,
        logos: ["rafting"],
        title: "Pacuare River – Rafting class II-III 10km/6mi",
        content: [
          "The adventure begins! Some of Costa Rica's most spectacular scenery is along the foothills of the Irazú and Turrialba volcanoes to the “put in” at La Cruz, the upper gateway to the raging Pacuare River. At the riverbank we eat lunch then load up into the rafts to paddle and PADDLE through pristine rainforest and canyon cliffs, spotted with deafening waterfalls. By afternoon we arrive at our comfortable riverside camp, El Nido del Tigre, nestled between the sounds of the rainforest and the hum of the river. You can wander off to explore this lush environment, looking out for toucans and boa constrictors and sloth, or simply relax in a hammock to the sounds of the river. (B, L, D)",
        ],
      },
      {
        day: 3,
        logos: ["rafting"],
        title: "Pacuare River – Rafting class III-IV 25km/16mi",
        content: [
          "The Pacuare carries us through virgin rainforest, cascading waterfalls and river carved canyons. We maneuver through the exhilarating rapids and in gentler sections you can see into the rainforest at the river’s edge. Along the way we hike to waterfalls, jump in to swim the softer rapids, and have lunch on the shore.AFter leaving the river near Siquirres we drive to the small town of La Fortuna, with Arenal Volcano right in front of us. There are plenty of great and interesting food options in Fortuna. (B, L)",
        ],
      },
      {
        day: 4,
        logos: ["trekking"],
        title:
          "Arenal Volcano – Hanging Bridges / La Fortuna’s Waterfall / Hot Springs",
        content: [
          "In the morning we spend about 2 hours on the Arenal hanging bridges with wonderful panoramic views of the Arenal Volcano and Lake Arenal, then continue to the breathtaking Rio Fortuna waterfall. The falls drops 120ft/35 meters into a large natural swimming pool. The hike down to the falls is steep through spectacular flora and fauna. The falls spill over into smaller gentler cascades and swim areas, nature's playground open for you. Spend the afternoon on your own exploring the town of La Fortuna, relaxing in your hotel, or sign up for an optional adventure -- a canopy zip line tour, a butterfly garden or ATV tour, get a massage, or go canyoning -- ropes, harnesses, waterfalls. Talk with us about extra tours you can do, and pricing. In the evening we are off to one of Arenal Volcano's famous hot springs, including dinner by the waterside. (B, D)",
        ],
      },
      {
        option: "Beach Option",
        day: "5-6",
        logos: ["pacific-palm"],
        title:
          "Manuel Antonio – Free afternoon / Manuel Antonio – Free day on the beach",
        content: [
          "It takes about 6 hours to get to Quepos, the closest town to Manuel Antonio National Park. The Pacific Coast of Costa Rica is a treasure chest of natural beauty. Rainforest dramatically meets beach and the sea. Manuel Antonio is a great place to relax and enjoy the beach or the National Park. You will often be awakened by the local monkeys – howler, white-faced and squirrel are the most common in the area, as well as iguanas and land crabs. This area is well developed for tourism, with numerous options for food and beach-related adventures, from snorkel or scuba to deep sea fishing, porpoise tours to mangrove nature trips. Talk with Coast to Coast sales staff to arrange your ideal extra activities. A list of the favorites starts below, but others, like fishing, require individual quotes depending on number of people, season, length of trip. (B)",
        ],
        optionalActivities: [
          {
            activity: "Canopy Tour (3 hours)",
            price: "$85, lunch included",
            description:
              "The adrenaline of zipping through tropical forest on a series of 14 suspension platforms high above the forest floor. The platforms are connected by steel cables. You spend about 45 minutes zipping on a pulley along the cables, moving from platform to platform using gravity.",
          },
          {
            activity: "Ocean kayaking & Snorkeling (approx.. 4 hours)",
            price: "$85, lunch included",
            description:
              "Paddle past islands and inlets where you see numerous marine aviary species and their nesting grounds.En route you stop at a hidden bay to rest and enjoy a snack. Take advantage of the 30 minute pit stop to snorkel on Bisanz beach. Water visibility varies depending on the season. You are most likely to spot parrotfish, starfish, angelfish and if you are lucky a sea turtle or two.After heading back to base camp enjoy a homemade meal.",
          },
          {
            activity: "Islas Damas Mangrove Kayak Trip (approx. 4 hours)",
            price: "$85, lunch or dinner included",
            description:
              "Paddle by sea kayak on protected inland waterways. This is a perfect place to learn this magical way of exploring coastal mangroves in search of wildlife such as Caimans, Sloths, Crocodiles, Boas, White-faced monkeys, egrets, and much more. Lunch at a local seafood restaurant.",
          },
          {
            activity: "Isla Damas Mangrove Boat Trip(4 hours)",
            price: "$90, lunch or dinner included",
            description:
              "Don’t miss Isla Damas and its wonderful wildlife just because you don’t want to kayak. Treat yourself to boating the estuary with a guide. There is great birding and wonderful vistas on these mangrove canals. We stop on the island to walk along the beach. With the bilingual guide accompanying you to point out wildlife, this is the best option to see a tropical riparian ecosystem -- animals and birds, Caimans, Sloths, Crocodiles, Boas, White-face monkeys, egrets, and much more. Lunch or dinner (depending on timing of the trip) included.",
          },
          {
            activity: "Manuel Antonio National Park (2.5 hours)",
            price: "$74",
            description:
              "Get the most out of your visit to Manuel Antonio National Park with an experienced naturalist guide. Learn about the history of the park, flora, fauna, bugs and wildlife. Includes snacks and refreshments and guests may remain in the park for as long as you wish after the tour has finished.",
          },
          {
            activity: "Catamaran & Snorkeling adventure (4 hours)",
            price: "$89 lunch or dinner included",
            description:
              "The Ocean King catamaran is brand new. At 100 feet length it has all the amenities. On board you have snacks, unlimited soft and natural drinks, and alcoholic beverages (8 total – 4 premium drinks and 4 beers). Enjoy the dolphin watching and snorkeling as well.",
          },
          {
            activity: "Surfing lessons (3 hours)",
            price: "$90",
            description:
              "A 3 hour group surf lesson (with a maximum of three students per instructor). Introduction to surfing fundamentals, paddling skills, surf board positioning, proper-stand-up, riding techniques, etiquette, and maneuvering. If students are kids, we assign no more than 2 per instructor. Your instructor provides the surfing equipment -- board length according to your level of experience (soft boards for beginners, etc), leash, and rash guard. Before hitting the waves, your instructor will give a detailed safety talk and demonstrate the first steps of surfing. After the briefing it's time for warm-up and a bit of 'play' - surfing is extremely physical. This will get you pumped! When you feel confident enough, your surfing instructor will accompany you into the waves and will continue to guide you throughout your session. For your hard-earned break, we treat you to fresh fruit and cold drinks then back into the water to catch more waves and practice your newly learned skills. includes transportation back to your hotel -- for hard earned R&R.",
          },
        ],
      },
      {
        option: "Beach Option",
        day: 7,
        logos: ["pacific-palm"],
        title:
          "Manuel Antonio – Free morning / Afternoon transfer back to San Jose",
        content: [
          "The morning is yours to enjoy before returning to your hotel in San Jose, about 3 hours away (30 minute local flight is also an option at an additional charge). Spend your last evening taking in the sights and sounds of San Jose. (B)",
        ],
      },
      {
        option: "Beach Option",
        day: 8,
        logos: ["departure"],
        title: "Departure",
        content: [
          "Coast to Coast will pick you up for your trip to the airport. (B)",
        ],
      },
      {
        option: "Monteverde Option",
        day: 5,
        logos: [],
        title: "Monteverde Cloud Forest – Horseback riding or motorboat",
        content: [
          "Monteverde is perhaps the world's most famous cloud forest. A cloud forest is a high altitude rainforest, from 4,500 feet (1,400 meters) to about 6,000 feet (1800 meters) of altitude, with an ecology distinct because each forest is isolated by intervening valleys, even from other cloud forests. Cloud forests exist only in tropical areas. The island-like occurrence of cloud forests makes species evolution almost as singular as Australia, the Galapagos and other aquatic island territories. The cooler atmosphere caused by the altitude creates forests of misty mountains with thriving bromeliads, epiphytes...species you will recognize as house plants that grow to giant cloud forest trees hundreds of feet tall, covered in vines and orchids and supporting hundreds of species of symbiotic plants and animals.\n\nIt takes about 4 hours to get to Monteverde by bus/boat/bus, first you will be transferred by land transportation from your hotel to Arenal Lake dam where you will take a 30 minute boat ride across picturesque Lake Arenal to the town of Río Chiquito on the south side of the Lake, then by car again to your hotel in Monteverde.\n\nBreakfast is included at your hotel. Choose your own spot for lunch and dinner in Monteverde. (B)",
        ],
      },
      {
        option: "Monteverde Option",
        day: 6,
        logos: ["trekking"],
        title: "Monteverde Cloud Forest",
        content: [
          "There's a variety of optional activities in Monteverde: guided or solo hike through Monteverde or Santa Elena Cloud Forest Reserve, Canopy Tour, a walk around the galleries and coffee houses of Santa Elena, Don Juan Coffee Tour, horseback riding, and the famous zip line tour through the canopy. You can pre-book adventures with Coast to Coast, or, in many instances, your hotel front desk can make the tours once you arrive. (B)",
        ],
      },
      {
        option: "Monteverde Option",
        day: 7,
        logos: ["trekking"],
        title: "Monteverde / Return to San Jose",
        content: [
          "You have some time this morning to continue to enjoy the Cloud Forest and have lunch before your pick up for the return journey to San Jose. The evening is free to enjoy the nightlife and restaurants of San Jose. (B)",
        ],
      },
      {
        option: "Monteverde Option",
        day: 8,
        logos: ["departure"],
        title: "Departure",
        content: [
          "Coast to Coast will pick you up for your trip to the airport. (B)",
        ],
      },
    ],
    needToKnow: [
      {
        title: "General Trip Information",
        sections: [
          {
            title: "Is this trip for me?",
            content: [
              "We divide our trips in 3 different levels (low ,medium and high) to give our participants an idea of the fitness level they should have for the trip. This trip is rated as Low",
              "Low: A trip designed for everyone! We ask that you have a healthy spirit of adventure and good general fitness. There are no specific requirements for whitewater rafting but you will fully participate in paddling.",
            ],
          },
          {
            title: "Daily routine",
            content: [
              "Upon arrival you will receive a package containing pick up times and vouchers for all activities, after each day tour you will have the opportunity to visit each destination and have a free afternoon to rest for the next day of adventure.",
            ],
          },
          {
            title: "Price includes",
            content: [
              "Accommodation, transportation, airport transfers, meals where indicated, group equipment (rafts, camping gear, etc.), experienced, bilingual & fun guides during the activities.",
            ],
          },
          {
            title: "Guides Qualifications",
            content: [
              "All Coast to Coast Adventures’ guides have First AID, CPR and Swiftwater Rescue and Head Guides have Wilderness First Responder.",
            ],
          },
          {
            title: "Group size",
            content: ["Average between 6 and 12."],
          },
          {
            title: "Vaccinations & Protection",
            content: [
              "Visa and vaccination requirements are subject to change and should be confirmed before departure. Consult your Doctor for latest advice on different prophylaxis available against malaria.",
            ],
          },
          {
            title: "Laundry",
            content: [
              "Having your cloth clean and dry while you travel is always a challenge. Laundry services are offered by some of our hotels for a charge. There will be times when you may want to do your own laundry so we suggest you bring non-polluting/biodegradable soap.",
            ],
          },
          {
            title: "Travel Insurance",
            content: [
              "CtoC Adventures requires all customers to have valid Travel Insurance.",
            ],
          },
        ],
      },
      {
        title: "Accommodations, Meals and Transportation",
        sections: [
          {
            title: "Accommodations",
            content: ["6 nights in Hotels", "1 night in set campsite"],
          },
          {
            title: "Transportation",
            content: [
              "All transportation according to the itinerary is included, starting with an airport transfer on the first day, along the trip with transportation from one area to the other, as well with transportation during the activities. Finishing with the transfer out to the airport.",
            ],
          },
          {
            title: "Meals",
            content: [
              "Eating is one of the wonderful experiences when travelling in Costa Rica. Included meals are a combination of restaurants, local food and delicious meals prepared by the tour guides.",
            ],
          },
          {
            title: "Meals Included",
            content: ["Breakfasts 7", "Lunches 2", "Dinners 2"],
          },
        ],
      },
      {
        title: "Gear / Kit Needed",
        sections: [
          {
            title: "Luggage Carrier",
            content: [
              "Duffle Bag",
              "Daypack (approx. 20-35 L)",
              "Daypack rain cover",
            ],
          },
          {
            title: "Upper Body",
            content: [
              "Shirts (poly-pro outer wear (for quick drying)",
              "Short sleeve and light colored long sleeve for sun protection)",
              "Lightweight rain jacket (Gortex, nylon or similar)",
              "Medium weight wool or fleece over-sweater",
              "Lightweight thermal underwear top (polypro/wool)",
            ],
          },
          {
            title: "Lower Body",
            content: [
              "Lightweight comfortable pants",
              "Shorts (quick dry)",
              "Lightweight rain pants",
            ],
          },
          {
            title: "Head",
            content: [
              "Sun/rain hat with brim (preferably 360˚ brim)",
              "Bandana",
            ],
          },
          {
            title: "Footwear",
            content: [
              "Lightweight adventure shoes or hiking boots",
              "Sandals with SNUG straps (Teva type)",
              "Lightweight fast drying hiking socks",
            ],
          },
          {
            title: "Personal Gear",
            content: [
              "Sunglasses (UVA and UVB resistant)",
              "Keeper straps for sunglasses",
              "1 headlamp (long lasting LED bulbs are the best option)",
              "Extra batteries (for headlamp, camera, etc)",
              "Additional small flashlight",
              "2-3 one quart, durable water bottles",
              "Prescription glasses or contacts (bring an extra pair of glasses)",
              "1 copy of your passport",
              "Insect repellent",
              "Waterproof sunscreen 30+ SPF",
              "Gallon & quart size Ziploc bags",
              "Towel",
            ],
          },
          {
            title: "Optional Gear",
            content: [
              "Dry bag",
              "Camera w/ waterproof camera bag",
              "Deck of cards",
              "Binoculars",
              "Sarongs (works as a towel, or quick cover-up)",
              "Writing materials (pen, pencil, notebook…)",
              "USB memory card (to share pictures with other participants)",
            ],
          },
          {
            title: "Note",
            content: [
              "No sleeping gear needed for overnight stays at El Nido del Tigre and Finca Pacuarito",
            ],
          },
        ],
      },
      {
        title: "Money Related",
        sections: [
          {
            title: "Money Exchange",
            content: [
              "A combination of US dollars and credit/debit cards is ideal, it’s best to bring notes in good condition and lower than 100USD. Travelers cheques are not a good idea. Cards are widely accepted but little stores or street sellers may only take local cash. There are ATMS along the way. Check with your bank to make sure your card will work internationally.",
            ],
          },
          {
            title: "Spending Money",
            content: [
              "How much you need will depend on your spending habits. You will need to pay for some meals, drinks, souvenirs and tipping. Here are a few things to keep in mind when you are deciding on the amount of spending money to take on your trip.",
            ],
          },
          {
            title: "Departure Tax",
            content: [
              "The Departure Tax in Costa Rica is $29. This needs to be paid at the airport using US or local cash or any major credit card. Pay this tax before going to the airline counter. Check with your airline if this fee is already included on your flight ticket.",
            ],
          },
          {
            title: "Tipping",
            content: [
              "It is customary in Latin America to tip service providers such as waiters, at approximately 10%, depending on the service. Also if you felt your local guides and drivers did an outstanding job, tipping is appreciated.",
            ],
          },
          {
            title: "Meal Costs",
            content: ["Lunch: $10 to $12", "Dinner: $12 to $20"],
          },
        ],
      },
      {
        title: "Costa Rica General Info",
        sections: [
          {
            title: "Geography",
            content: [
              "We Costa Rica, in Central America is bordered by Nicaragua to the North and Panama to the South with the Pacific Ocean to the West and the Caribbean Sea to the East.",
              "Abolishing its army in 1948, it is the only Latin American country included in the list of the world's 22 older democracies. Costa Rica has consistently been among the top Latin American countries in the Human Development Index, ranked 62nd in the world in 2010, and is cited by the UNDP as one of the countries that has attained much higher human development than other countries at the same income levels. The country is ranked 3rd in the world, and 1st among the Americas, in terms of the 2010 Environmental Performance Index.",
              'In 2007 the Costa Rican government announced plans for Costa Rica to become the first carbon neutral country by 2021. According to the New Economics Foundation, Costa Rica ranks first in the Happy Planet Index and is the "greenest" country in the world.',
              "The highest regions of Costa Rica are found in the center of the country while the lowlands, which are more extensive and flat, extend to the Caribbean Coast on the northeast and to the northwest on the Pacific side. The Pacific Coast is more dissected, forming many bays, capes, cliffs and inlets. The Costa Rican mountain ranges form an independent group, part of the Central American massif. Three of these ranges run roughly from northwest to southeast, with a fourth (The Central Range) crossing them at the widest part of the country, thus forming a huge cross. Two volcanic ranges dominate northwestern Costa Rica. The first is the Guanacaste Volcanic Chain with its Orosi, Miravalles, Tenorio, Rincón de la Vieja and Arenal volcanos. Arenal offers amazing views, especially breathtaking when is clear, while Rincón de la Vieja's activity keeps natural mud pots in its foothills, bubbling permanently. The second range is the Tilarán Volcanic Chain in the northwest, formed by the hills of Abangares, Aguacate and Catedral. In the transverse chain of the central highlands, Poás, Barva, Irazú and Turrialba Volcanos are more accessible. Finally, to the south, are Costa Rica's highest mountains, those of the non-volcanic Talamanca Range. Of these, Chirripó Mountain is the most impressive, being the highest mountain in the country, 3,821 meters above sea level.",
              "The Caribbean coastline stretches for 212 km in a generally north-south direction. It is divided into two distinct sections; the San Juan River on the Nicaraguan border to the city of Limón and that which extends from the city of Limón to the Sixaola River on the border with Panamá. The first section consists of a long stretch of coastline that separates a series of estuary lagoons and waterways, fed by numerous rivers, from the sea. These make up the famous Tortuguero Canals, over 100 km long. These navigable canals are the habitat of seven species of turtles.",
              "The Pacific Coast extends over 1,016 km from one border to the other and offers a wide variety of geological features. From Salinas Bay in the north to the tip of Burica Point in the south, the Pacific Beaches of Costa Rica offer an almost infinite number of beaches for tourist enjoyment.",
              "The Santa Elena, Nicoya and Osa Peninsulas are the principal ones on the Pacific side.",
              "Two ferries cross the Nicoya Gulf, shortening the route to the isolated beaches of the southern tip of the Nicoya Peninsula. The shoreline of the gulf has many bays and promontories and is dotted with small islands.",
              "The Osa Peninsula, covered by great tracts of virgin forest with the most extensive variety of native species to be found in the country, lies on the western side of the Golfo Dulce.",
            ],
          },
        ],
      },
    ],
    photos: [
      require("@/assets/trips/costaricanAdventure/cra1.jpg"),
      require("@/assets/trips/costaricanAdventure/cra2.jpg"),
      require("@/assets/trips/costaricanAdventure/cra3.jpg"),
      require("@/assets/trips/costaricanAdventure/cra4.jpg"),
      require("@/assets/trips/costaricanAdventure/cra5.jpg"),
      require("@/assets/trips/costaricanAdventure/cra.jpg"),
    ],
  },
  volcanoesRiversBeaches: {
    code: "volcanoesRiversBeaches",
    name: "Volcanoes, Rivers & Beaches",
    headerSrc: require("@/assets/trips/volcanoesRiversBeaches/header.jpg"),
    overview: {
      length: "8 days",
      mapSrc: require("@/assets/trips/volcanoesRiversBeaches/map.jpg"),
      sellText:
        "New for 2020, this multi-activity trip was designed for a post Covid19 world. All accommodations are private and transport is minimal. For the majority of the time you are outside enjoying mountain biking, rappelling & zip lining, whitewater rafting and hiking.",
      overviewText: [
        "This multi-activity adventure trip start from the lunar like crater of the Irazu Volcano with an exhilarating mountain bike ride downhill passing the active Turrialba Volcano on the way to San Rafael. A full day in the area allows for more mountain biking and a cannoning trip that involves rappelling down waterfalls and zipping through the jungle. Next we head to the nearby Pacuare River for a lifetime 2 day whitewater rafting experience on this river with an overnight at El Nido del Tigre. We finish the adventure in the sleepy community of Cahuita on the Caribbean Coast. Here you can hike the nearby National Park enjoying its beaches and abundant flora and Fauna. The park is one of the best places to see monkeys and sloths.",
      ],
      promotionText: "10% discount on the January 20th 2024 departure",
      highlights: [
        "Mountain biking down a 3400 meter high volcano.",
        "Rappelling down waterfalls and zipping through the jungle.",
        "Whitewater rafting the magnificent Pacuare River.",
        "Comfortable riverside camping - El Nido del Tigre.",
        "Beaches and wildlife of Cahuita National Park.",
      ],
      tripDates: [
        {
          year: 2024,
          dates: ["Aug 3 - 10", "Oct 26 - Nov 2", "*Dec 28 - Jan 4"],
        },
        {
          year: 2025,
          dates: [
            "Jan 18 - 25",
            "Mar 15 - 22",
            "May 24 - 31",
            "Aug 2 - 9",
            "Oct 25 - Nov 1",
            "*Dec 28 - Jan 4",
          ],
        },
      ],
      note: "NOTE: All dates are guaranteed departures. We will open a new date with a minimum of 4 people.",
      priceSection: {
        primary: {
          price: "$1,915 USD",
          pp: "per person",
          singleSupp: "Single Supplement $510",
          validity: "(Price valid until Oct. 31, 2024)",
        },
        peakSeason: {
          priceText: "PEAK SEASON",
          price: "$2,225 USD",
          pp: "per person",
          singleSupp: "Single Supplement $555",
          validity: "(Christmas 2024)",
        },
        secondary: {
          price: "$1,995 USD",
          pp: "per person",
          singleSupp: "Single Supplement $540",
          validity: "(Nov. 1, 2024 to Oct. 31, 2025)",
        },
      },
    },
    itinerary: [
      {
        day: 1,
        logos: ["arrival"],
        title: "Arrival in Costa Rica – Hotel in San Jose",
        content: [
          "A Coast to Coast representative will meet your flight then go to your hotel in San Jose.",
        ],
      },
      {
        day: 2,
        logos: ["biking"],
        title: "Irazú and Turrialba Volcanoes – Mountain Biking 35km/22mi",
        content: [
          "The Fun Begins. We leave San Jose heading east to Cartago and then up to the top of the Irazu Volcano, Costa Ricas tallest Volcano at 3400 meters above sea level. After some time to explore the lunar like craters of the volcano we begin the first mountain bike section of the trip. The first part of the route takes us across the saddle that connects the Irazu Volcano with the Turrialba Volcano. The Turrialba Volcano is quite active and it is common to see smoke coming out of its crater. We pass a few kilometers from the crater then begin a long descent past several dairy and vegetable farms on our way down the volcano. Tonight we stay in the clouds, halfway down the Turrialba Volcano at the very comfortable Adventure House. (B, L, D)",
        ],
      },
      {
        day: 3,
        logos: ["biking", "canopy"],
        title: "Turrialba – Mountain Biking & Canyoning 18km/11mi",
        content: [
          "Back on the bikes for a ride to a nearby waterfall with some time for a swim at the base of the falls. From here we continue down to the town of Turrialba for some lunch at a local restaurant. The afternoon is spent canyoning - abseiling down waterfalls and zipping through the jungle. After this full on day we head back to San Rafael Camp for another evening in the clouds. (B, L, D)",
        ],
      },
      {
        day: 4,
        logos: ["rafting"],
        title: "Pacuare River – Rafting class II-III 10km/6mi",
        content: [
          "This morning we transfer to the put in of the Pacuare River. After gearing up and receiving a whitewater rafting safety briefing we load up into the rafts and begin the first of 2 days on the Pacuare River. The river takes us through pristine rainforest and canyon cliffs, spotted with deafening waterfalls. The first day of rafting is a great warm up featuring continuous class II & III rapids By afternoon we arrive at our comfortable riverside camp, nestled between the sounds of the rainforest and the hum of the river. You can wander off to explore this lush environment, looking out for toucans and boa constrictors and sloth, or simply relax in a hammock to the sounds of the river. (B, L, D)",
        ],
      },
      {
        day: 5,
        logos: ["rafting", "caribbean-palm"],
        title: "Pacuare River – Rafting class III-IV 25km/16mi",
        content: [
          "Today the river picks up in intensity as we maneuver through the exhilarating class III – IV rapids and enjoy the rainforest at the river’s edge. Along the way we hike to waterfalls, jump in to swim the softer rapids, and have lunch on the shore. We finish at Finca Pacuarito where we shower and drive to Cahuita, 2 hours away. (B, L)",
        ],
      },
      {
        day: 6,
        logos: ["caribbean-palm"],
        title: "Caribbean Coast – Free day on the beach",
        content: [
          "Free time to enjoy Costa Rica’s Caribbean Coast. Puerto Viejo is also famous as a surfing destination. It has a relaxed atmosphere with a mix of Latino, Afro-Caribbean and Bribri indigenous cultures. Locals usually speak their own language called Patois (pronounced “patua”) which is a mix of Afro-Caribbean English, Spanish and French. Puerto Viejo is a lively place for tourists to have a fun and relaxing experience. This area offers a lot of attractions and activities. Optional activities include: scuba diving, snorkeling, visit to Cahuita National Park, the Sloth Sanctuary or the Kekoldi Indian Reserve & waterfalls. (B)",
        ],
      },
      {
        day: 7,
        logos: ["caribbean-palm"],
        title: "Beach / Return to San José",
        content: [
          "The morning is free to relax around the hotel or on the beach. After lunch we head back to San José. The transfer back to San Jose takes 4 to 5 hours and passes through the spectacular Braulio Carrillo National Park, famous for its breathtaking views of high cloud forest, misty mountains and steep river-carved canyons. Evening is free to explore San Jose. (B)",
        ],
      },
      {
        day: 8,
        logos: ["departure"],
        title: "Departure",
        content: [
          "Coast to Coast will take you to the airport. Hard to believe you'll soon be back in lands without monkeys and tropical breezes and the great new friends you have made on your epic adventure in Costa Rica. (B)",
        ],
      },
    ],
    needToKnow: [
      {
        title: "General Trip Information",
        sections: [
          {
            title: "Is this trip for me?",
            content: [
              "We divide our trips in 3 different levels (low ,medium and high) to give our participants an idea of the fitness level they should have for the trip. This trip is rated as Medium",
              "Medium: You need to be reasonably fit and have good stamina to take part in this trip. Some experience in mountain biking will make the trip more enjoyable. There are no specific requirements for whitewater rafting or sea kayaking but you will fully participate in paddling so fitness and stamina are a must.",
            ],
          },
          {
            title: "Daily routine",
            content: [
              "Our days usually start with fresh coffee and breakfast around 6:30. All packed and ready to go for our activity by 8:00. During the day you will have lots of time for pictures, water breaks and enjoying the scenery. The activities will be done at a comfortable pace for everyone, set by the guide. Around mid-day we will stop for lunch at a local restaurant or a picnic lunch made by the guides. The group will finish the day around 3:00 with plenty of time to rest. Dinner is usually served around 7:00 followed by a briefing for the next day.",
            ],
          },
          {
            title: "Price includes",
            content: [
              "Accommodation, transportation, airport transfers, meals where indicated, group equipment (bikes, rafts, camping gear, etc.), support vehicle and experienced, bilingual & fun guides throughout the trip.",
            ],
          },
          {
            title: "Group leader",
            content: [
              "Our Tour Leaders are a key part of any CtoC trip. All expertly trained and experienced in every field of activity, bilingual and qualified in first aid and/or Wilderness First responder. They are passion for what they do and full with knowledge ready to share with you. They are responsible for ensuring the complete safety, well-being and enjoyment of our guests but retain a flexible and fun attitude.",
            ],
          },
          {
            title: "Guides Qualifications",
            content: [
              "All Coast to Coast Adventures’ guides have First AID, CPR and Swiftwater Rescue and Head Guides have Wilderness First Responder.",
            ],
          },
          {
            title: "Group size",
            content: ["Average between 6 and 12."],
          },
          {
            title: "Bikes Specifications",
            content: [
              "29ers Mountain Bike (Scott and Trek) or similar.",
              "Every bike has front suspension and disk brakes. Participants can bring their own pedals, seats and/or accessories if they want.",
            ],
          },
          {
            title: "Vaccinations & Protection",
            content: [
              "Vaccination requirements are subject to change and should be confirmed before departure. Consult your Doctor for latest advice on different prophylaxis available against malaria.",
            ],
          },
          {
            title: "Visas",
            content: [
              "All countries require a valid passport (with a minimum 6 months validity). Contact your local embassy, or consulate for the most up-to-date visa requirements. IT'S YOUR RESPONSIBILITY TO HAVE THE CORRECT TRAVEL DOCUMENTATION.",
            ],
          },
          {
            title: "Laundry",
            content: [
              "Having your cloth clean and dry while you travel is always a challenge. Laundry services are offered by some of our hotels for a charge. There will be times when you may want to do your own laundry so we suggest you bring non-polluting/biodegradable soap.",
            ],
          },
          {
            title: "Travel Insurance",
            content: [
              "CtoC Adventures requires all customers to have valid Travel Insurance.",
            ],
          },
        ],
      },
      {
        title: "Accommodations, Meals and Transportation",
        sections: [
          {
            title: "Accommodations",
            content: [
              "1 night in campsite",
              "2 nights in Adventure House",
              "4 nights in hotels",
            ],
          },
          {
            title: "Transportation",
            content: [
              "All transportation according to the itinerary is included, starting with an airport transfer on the first day, along the trip with transportation from one area to the other, as well with transportation during the activities. Finishing with the transfer out to the airport.",
            ],
          },
          {
            title: "Meals",
            content: [
              "Eating is one of the wonderful experiences when travelling in Costa Rica. Included meals are a combination of restaurants, local food and delicious meals prepared by the tour guides.",
            ],
          },
          {
            title: "Meals Included",
            content: ["Breakfasts 7", "Lunches 4", "Dinners 3"],
          },
        ],
      },
      {
        title: "Gear / Kit Needed",
        sections: [
          {
            title: "Luggage Carrier",
            content: [
              "Duffle Bag",
              "Daypack (approx. 20-35 L)",
              "Daypack rain cover",
            ],
          },
          {
            title: "Upper Body",
            content: [
              "Shirts (poly-pro outer wear for quick drying)",
              "Short sleeve and light colored long sleeve for sun protection",
              "Lightweight rain jacket (Gortex, nylon or similar)",
              "Medium weight wool or fleece over-sweater",
              "Lightweight thermal underwear top (polypro/wool)",
            ],
          },
          {
            title: "Lower Body",
            content: [
              "Lightweight comfortable pants",
              "Shorts (quick dry)",
              "Lightweight rain pants",
            ],
          },
          {
            title: "Head",
            content: [
              "Sun/rain hat with brim (preferably 360˚ brim)",
              "Bandana",
            ],
          },
          {
            title: "Footwear",
            content: [
              "Lightweight adventure shoes or hiking boots",
              "Sandals with SNUG straps (Teva type)",
              "Lightweight fast drying hiking socks",
            ],
          },
          {
            title: "Personal Gear",
            content: [
              "Sunglasses (UVA and UVB resistant)",
              "Keeper straps for sunglasses",
              "1 headlamp (long lasting LED bulbs are the best option)",
              "Extra batteries (for headlamp, camera, etc)",
              "Additional small flashlight",
              "2-3 one quart, durable water bottles",
              "Prescription glasses or contacts (bring an extra pair of glasses)",
              "1 copy of your passport",
              "Insect repellent",
              "Waterproof sunscreen 30+ SPF",
              "Gallon & quart size Ziploc bags",
              "Towel",
            ],
          },
          {
            title: "Optional Gear",
            content: [
              "Dry bag",
              "Camera w/ waterproof camera bag",
              "Deck of cards",
              "Binoculars",
              "Sarongs (works as a towel, or quick cover-up)",
              "Writing materials (pen, pencil, notebook…)",
              "USB memory card (to share pictures with other participants)",
            ],
          },
          {
            title: "Biking",
            content: [
              "Bike shorts with padded seat",
              "Bike water bottle",
              "Bike helmet",
              "Bike gloves (can also be used for paddling)",
              "Bike shirts (optional)",
            ],
          },
          {
            title: "Note",
            content: [
              "Feel free to bring your own pedals and seat if you want.",
              "We supply the basic equipment needed for the activities. We ask our customers to bring their own cycling helmet.",
            ],
          },
        ],
      },
      {
        title: "Money Related",
        sections: [
          {
            title: "Money Exchange",
            content: [
              "A combination of US dollars and credit/debit cards is ideal, it’s best to bring notes in good condition and lower than 100USD. Travelers cheques are not a good idea. Cards are widely accepted but little stores or street sellers may only take local cash. There are ATMS along the way. Check with your bank to make sure your card will work internationally.",
            ],
          },
          {
            title: "Spending Money",
            content: [
              "How much you need will depend on your spending habits. You will need to pay for some meals, drinks, souvenirs and tipping. Here are a few things to keep in mind when you are deciding on the amount of spending money to take on your trip.",
            ],
          },
          {
            title: "Departure Tax",
            content: [
              "The Departure Tax in Costa Rica is $29. This needs to be paid at the airport using US or local cash or any major credit card. Pay this tax before going to the airline counter. Check with your airline if this fee is already included on your flight ticket.",
            ],
          },
          {
            title: "Tipping",
            content: [
              "It is customary in Latin America to tip service providers such as waiters, at approximately 10%, depending on the service. Also if you felt your local guides and drivers did an outstanding job, tipping is appreciated.",
            ],
          },
          {
            title: "Meal Costs",
            content: ["Lunch: $10 to $12", "Dinner: $12 to $20"],
          },
        ],
      },
      {
        title: "Costa Rica General Info",
        sections: [
          {
            title: "Geography",
            content: [
              "Costa Rica, in Central America, is bordered by Nicaragua to the North and Panama to the South with the Pacific Ocean to the West and the Caribbean Sea to the East.",
              "Abolishing its army in 1948, it is the only Latin American country included in the list of the world's 22 older democracies. Costa Rica has consistently been among the top Latin American countries in the Human Development Index, ranked 62nd in the world in 2010, and is cited by the UNDP as one of the countries that has attained much higher human development than other countries at the same income levels. The country is ranked 3rd in the world, and 1st among the Americas, in terms of the 2010 Environmental Performance Index.",
              'In 2007 the Costa Rican government announced plans for Costa Rica to become the first carbon-neutral country by 2021. According to the New Economics Foundation, Costa Rica ranks first in the Happy Planet Index and is the "greenest" country in the world.',
              "The highest regions of Costa Rica are found in the center of the country while the lowlands, which are more extensive and flat, extend to the Caribbean Coast on the northeast and to the northwest on the Pacific side. The Pacific Coast is more dissected, forming many bays, capes, cliffs and inlets. The Costa Rican mountain ranges form an independent group, part of the Central American massif. Three of these ranges run roughly from northwest to southeast, with a fourth (The Central Range) crossing them at the widest part of the country, thus forming a huge cross. Two volcanic ranges dominate northwestern Costa Rica. The first is the Guanacaste Volcanic Chain with its Orosi, Miravalles, Tenorio, Rincón de la Vieja and Arenal volcanos. Arenal offers amazing views, especially breathtaking when is clear, while Rincón de la Vieja's activity keeps natural mud pots in its foothills, bubbling permanently. The second range is the Tilarán Volcanic Chain in the northwest, formed by the hills of Abangares, Aguacate and Catedral. In the transverse chain of the central highlands, Poás, Barva, Irazú and Turrialba Volcanos are more accessible. Finally, to the south, are Costa Rica's highest mountains, those of the non-volcanic Talamanca Range. Of these, Chirripó Mountain is the most impressive, being the highest mountain in the country, 3,821 meters above sea level.",
              "The Caribbean coastline stretches for 212 km in a generally north-south direction. It is divided into two distinct sections; the San Juan River on the Nicaraguan border to the city of Limón and that which extends from the city of Limón to the Sixaola River on the border with Panamá. The first section consists of a long stretch of coastline that separates a series of estuary lagoons and waterways, fed by numerous rivers, from the sea. These make up the famous Tortuguero Canals, over 100 km long. These navigable canals are the habitat of seven species of turtles.",
              "The Pacific Coast extends over 1,016 km from one border to the other and offers a wide variety of geological features. From Salinas Bay in the north to the tip of Burica Point in the south, the Pacific Beaches of Costa Rica offer an almost infinite number of beaches for tourist enjoyment.",
              "The Santa Elena, Nicoya and Osa Peninsulas are the principal ones on the Pacific side.",
              "Two ferries cross the Nicoya Gulf, shortening the route to the isolated beaches of the southern tip of the Nicoya Peninsula. The shoreline of the gulf has many bays and promontories and is dotted with small islands.",
              "The Osa Peninsula, covered by great tracts of virgin forest with the most extensive variety of native species to be found in the country, lies on the western side of the Golfo Dulce.",
            ],
          },
        ],
      },
    ],
    photos: [
      require("@/assets/trips/volcanoesRiversBeaches/vbr1.jpg"),
      require("@/assets/trips/volcanoesRiversBeaches/vbr2.jpg"),
      require("@/assets/trips/volcanoesRiversBeaches/vbr3.jpg"),
      require("@/assets/trips/volcanoesRiversBeaches/vbr4.jpg"),
      require("@/assets/trips/volcanoesRiversBeaches/vbr5.jpg"),
    ],
  },
  volcanoToWaves: {
    code: "volcanoToWaves",
    name: "From Volcano to Waves",
    subName: "A Thrilling Costa Rica Expedition",
    headerSrc: require("@/assets/trips/volcanoToWaves/header.jpg"),
    overview: {
      length: "7 days",
      mapSrc: require("@/assets/trips/volcanoToWaves/map.jpg"),
      sellText:
        "Embark on an unforgettable adventure as you journey from the majestic Arenal Volcano to the stunning Caribbean waves. This unique trip combines breathtaking landscapes, rich cultural experiences, and thrilling outdoor activities, allowing you to explore the enchanting beauty of the country. Get ready for an incredible exploration that immerses you in the contrasting wonders of nature!",
      overviewText: [
        "A Thrilling Costa Rica Expedition.",
        "This trip is a favorite among adventurers eager to explore the iconic sights of Costa Rica while tackling the thrilling rapids of its rivers.",
        "Hiking in the Arenal volcano area is a unique way to view the forest and play in Fortuna Waterfall plus relaxing and fun hot springs. However, the real adventure awaits on the Pacuare River, where you can experience the excitement of whitewater rafting through Class III-IV rapids, swim in the refreshing waters, and feel the exhilarating rush of giant rainforest waterfalls cascading over you.",
      ],
      highlights: [
        "Explore the breathtaking La Fortuna Waterfall.",
        "Unwind in the rejuvenating thermal waters that surround the Arenal Volcano area.",
        "Experience the thrill of whitewater rafting on the legendary Pacuare River, where adrenaline meets stunning natural beauty.",
        "Discover the pristine sandy beaches and rich biodiversity near Puerto Viejo de Talamanca and Cahuita National Park, where you can immerse yourself in nature.",
        "Enhance your journey with options like snorkeling in crystal-clear waters, scuba diving among vibrant marine life, surfing the perfect waves, soaring through the treetops on a canopy zip line, or hiking scenic trails teeming with wildlife. Your perfect adventure awaits!",
      ],
      isPrivateItinerary: true,
      tripDates: [],
      note: "NOTE: All dates are guaranteed departures. We will open a new date with a minimum of 4 people.",
      priceSection: {
        primary: {
          price: "$1,120 USD",
          pp: "per person",
          singleSupp: "",
          validity: "(Nov. 1, 2024 to Oct. 31, 2025)",
        },
        peakSeason: {
          priceText: "",
          price: "",
          pp: "",
          singleSupp: "",
          validity: "",
        },
        secondary: {
          price: "",
          pp: "",
          singleSupp: "",
          validity: "",
        },
      },
    },
    itinerary: [
      {
        day: 1,
        logos: ["arrival"],
        title: "Arrival in Costa Rica – Hotel in San Jose",
        content: [
          "Arrival to Costa Rica. After welcoming you at the San José Airport, a Coast to Coast representative will bring you to a comfortable hotel in the city.",
        ],
      },
      {
        day: 2,
        logos: ["volcano"],
        title: "Arenal Volcano – Hot Springs at night",
        content: [
          "Enjoy a scenic 3.5-hour drive to La Fortuna, where the majestic Arenal Volcano awaits. This vibrant town boasts a diverse culinary scene with plenty of dining options.",
          "Upon arrival, you can explore La Fortuna, relax at your hotel, or choose from several optional adventures, including zip-lining, ATV rides, guided volcano hikes, coffee and chocolate tours, or canyoning in stunning waterfalls. Contact us for more tour options and pricing.",
          "As the day winds down, unwind in the famous hot springs and savor a delightful dinner. (B, D)",
        ],
      },
      {
        day: 3,
        logos: ["trekking"],
        title: "Arenal Volcano – La Fortuna’s Waterfall / Relaxation",
        content: [
          "One of the crown jewels of Fortuna, the breathtaking La Fortuna Waterfall is an experience like no other. Pouring down an impressive 210 feet (64 meters), this natural wonder is nourished by over 15 feet (4 meters) of rain each year, creating a mesmerizing spectacle that captivates all who visit.",
          "Your adventure begins with a descent down a picturesque trail that stretches for 1,600 feet (500 meters) in altitude. As you navigate through lush tropical flora, the sound of cascading water becomes increasingly pronounced, building anticipation for the refreshing swim awaiting you in the sparkling, naturally formed pool below. The invigorating swim amid the stunning backdrop of the waterfall is truly a reward for your efforts—but keep in mind, the ascent back up will certainly get your heart racing!",
          "Once you’ve conquered the waterfall, the day is yours to explore and indulge in all that La Fortuna has to offer. Our team is here to help you tailor your adventure. Engage with us about exciting extra tours and competitive pricing that cater to your interests. With so much to explore, your day in La Fortuna is bound to be unforgettable! (B)",
        ],
      },
      {
        day: 4,
        logos: ["rafting"],
        title: "Pacuare River – Rafting (27km)",
        content: [
          "Get ready for an adrenaline-pumping escape as we embark on an unforgettable journey to the legendary Pacuare River! We rise with the sun to seize an entire day filled with heart-pounding whitewater rafting across 17 miles (27 km) of pristine, world-class rapids, nestled deep within untouched forests and steep, dramatic canyons. The Pacuare isn't just any river; it’s the very reason Costa Rica became the ultimate adventure destination long before tourism flourished.",
          "As we navigate the roaring waters, you'll be treated to breathtaking views of lush virgin rainforest, cascading waterfalls, and stunning river-carved canyons. Feel the thrill as we tackle exhilarating rapids, then catch your breath in the calmer stretches where you can admire the vibrant flora and fauna at the river's edge.",
          "But the adventure doesn’t stop there! We’ll take breaks to hike to hidden waterfalls, dive into the refreshing waters of softer rapids, and savor a delicious picnic lunch right on the shore. This journey is designed for thrill-seekers aged 14 and up—prepare yourself for a genuine whitewater experience that requires strength and courage, but no prior rafting experience needed!",
          "After conquering the mighty Pacuare, we’ll venture to the stunning shores on the Caribbean coast, where relaxation and new adventures await you. Pack your sense of adventure—it’s time to make memories that will last a lifetime! (B, L)",
        ],
      },
      {
        day: 5,
        logos: ["caribbean-palm"],
        title: "Caribbean coast – Free day on the beach",
        content: [
          "Experience the serenity of the crystal-clear blue waters of the Caribbean coast, a region that offers a vibrant tapestry of Latino, Afro-Caribbean, and Bribri indigenous cultures—distinct from the rest of Costa Rica. Here, locals often speak Patois, a fascinating blend of Jamaican English, Spanish, and French, adding to the unique atmosphere.",
          "Adventurous spirits can embark on snorkeling trips or kayak through uninhabited estuaries, immersing themselves in the rich riparian ecosystems. Just 15 miles south from Cahuita National Park, lies Puerto Viejo, the reggae capital of Costa Rica, where an eclectic array of restaurants offers culinary delights from around the globe. Savor flavors from French and Italian cuisines to sushi, vegan dishes, Indonesian fare, and of course, fresh seafood.",
          "Optional activities abound, including scuba diving, snorkeling excursions, visits to chocolate production facilities (complete with tastings), and exploration of the Jaguar Rescue Center and other animal preserves. You can also discover the Kekoldi Indian Reserve and its stunning waterfalls.",
          "Let us help you customize your adventure with additional tours and pricing information! (B)",
        ],
      },
      {
        day: 6,
        logos: ["caribbean-palm"],
        title:
          "Caribbean coast – Free morning / Afternoon transfer back to San Jose",
        content: [
          "Your final morning on the beach unfolds in tranquility. After a leisurely lunch, we will journey back to San José, traversing the stunning Braulio Carrillo National Park. Prepare to be mesmerized by the majestic mountains and the vibrant sounds of the rainforest. (B)",
        ],
      },
      {
        day: 7,
        logos: ["departure"],
        title: "Departure",
        content: [
          "A Coast to Coast representative will warmly greet you at your hotel to assist with your journey home. While it’s tough to say goodbye, remember that wonderful adventures await you in the future—you'll be back before you know it! (B)",
        ],
      },
    ],
    needToKnow: [
      {
        title: "General Trip Information",
        sections: [
          {
            title: "Is this trip for me?",
            content: [
              "We divide our trips in 3 different levels (low, medium and high) to give our participants an idea of the fitness level they should have for the trip. This trip is rated as Low",
              "Low: A trip designed for everyone! We ask that you have a healthy spirit of adventure and good general fitness. There are no specific requirements for whitewater rafting but you will fully participate in paddling.",
            ],
          },
          {
            title: "Daily routine",
            content: [
              "Upon arrival you will receive a package containing pick up times and vouchers for all activities, after each day tour you will have the opportunity to visit each destination and have a free afternoon to rest for the next day of adventure.",
            ],
          },
          {
            title: "Price includes",
            content: [
              "Accommodation, transportation, airport transfers, meals where indicated, group equipment (rafts, camping gear, etc.), experienced, bilingual & fun guides during the activities.",
            ],
          },
          {
            title: "Guides Qualifications",
            content: [
              "All Coast to Coast Adventures’ guides have First AID, CPR and Swiftwater Rescue and Head Guides have Wilderness First Responder.",
            ],
          },
          {
            title: "Group size",
            content: ["Average between 6 and 12."],
          },
          {
            title: "Vaccinations & Protection",
            content: [
              "Visa and vaccination requirements are subject to change and should be confirmed before departure. Consult your Doctor for latest advice on different prophylaxis available against malaria.",
            ],
          },
          {
            title: "Laundry",
            content: [
              "Having your cloth clean and dry while you travel is always a challenge. Laundry services are offered by some of our hotels for a charge. There will be times when you may want to do your own laundry so we suggest you bring non-polluting/biodegradable soap.",
            ],
          },
          {
            title: "Travel Insurance",
            content: [
              "CtoC Adventures requires all customers to have valid Travel Insurance.",
            ],
          },
        ],
      },
      {
        title: "Accommodations, Meals and Transportation",
        sections: [
          {
            title: "Accommodations",
            content: ["6 nights in Hotels"],
          },
          {
            title: "Transportation",
            content: [
              "All transportation according to the itinerary is included, starting with an airport transfer on the first day, along the trip with transportation from one area to the other, as well with transportation during the activities. Finishing with the transfer out to the airport.",
            ],
          },
          {
            title: "Meals",
            content: [
              "Eating is one of the wonderful experiences when travelling in Costa Rica. Included meals are a combination of restaurants, local food and delicious meals prepared by the tour guides.",
            ],
          },
          {
            title: "Meals Included",
            content: ["Breakfasts 6", "Lunches 1", "Dinners 1"],
          },
        ],
      },
      {
        title: "Gear / Kit Needed",
        sections: [
          {
            title: "Luggage Carrier",
            content: [
              "Duffle Bag",
              "Daypack (approx. 20-35 L)",
              "Daypack rain cover",
            ],
          },
          {
            title: "Upper Body",
            content: [
              "Shirts (poly-pro outer wear for quick drying)",
              "Short sleeve and light colored long sleeve for sun protection",
              "Lightweight rain jacket (Gortex, nylon or similar)",
              "Medium weight wool or fleece over-sweater",
              "Lightweight thermal underwear top (polypro/wool)",
            ],
          },
          {
            title: "Lower Body",
            content: [
              "Lightweight comfortable pants",
              "Shorts (quick dry)",
              "Lightweight rain pants",
            ],
          },
          {
            title: "Head",
            content: [
              "Sun/rain hat with brim (preferably 360˚ brim)",
              "Bandana",
            ],
          },
          {
            title: "Footwear",
            content: [
              "Lightweight adventure shoes or hiking boots",
              "Sandals with SNUG straps (Teva type)",
              "Lightweight fast drying hiking socks",
            ],
          },
          {
            title: "Personal Gear",
            content: [
              "Sunglasses (UVA and UVB resistant)",
              "Keeper straps for sunglasses",
              "1 headlamp (long lasting LED bulbs are the best option)",
              "Extra batteries (for headlamp, camera, etc)",
              "Additional small flashlight",
              "2-3 one quart, durable water bottles",
              "Prescription glasses or contacts (bring an extra pair of glasses)",
              "1 copy of your passport",
              "Insect repellent",
              "Waterproof sunscreen 30+ SPF",
              "Gallon & quart size Ziploc bags",
              "Towel",
            ],
          },
          {
            title: "Optional Gear",
            content: [
              "Dry bag",
              "Camera w/ waterproof camera bag",
              "Deck of cards",
              "Binoculars",
              "Sarongs (works as a towel, or quick cover-up)",
              "Writing materials (pen, pencil, notebook…)",
              "USB memory card (to share pictures with other participants)",
            ],
          },
        ],
      },
      {
        title: "Money Related",
        sections: [
          {
            title: "Money Exchange",
            content: [
              "A combination of US dollars and credit/debit cards is ideal, it’s best to bring notes in good condition and lower than 100USD. Travelers cheques are not a good idea. Cards are widely accepted but little stores or street sellers may only take local cash. There are ATMS along the way. Check with your bank to make sure your card will work internationally.",
            ],
          },
          {
            title: "Spending Money",
            content: [
              "How much you need will depend on your spending habits. You will need to pay for some meals, drinks, souvenirs and tipping. Here are a few things to keep in mind when you are deciding on the amount of spending money to take on your trip.",
            ],
          },
          {
            title: "Departure Tax",
            content: [
              "The Departure Tax in Costa Rica is $29. This needs to be paid at the airport using US or local cash or any major credit card. Pay this tax before going to the airline counter. Check with your airline if this fee is already included on your flight ticket.",
            ],
          },
          {
            title: "Tipping",
            content: [
              "It is customary in Latin America to tip service providers such as waiters, at approximately 10%, depending on the service. Also if you felt your local guides and drivers did an outstanding job, tipping is appreciated.",
            ],
          },
          {
            title: "Meal Costs",
            content: ["Lunch: $10 to $12", "Dinner: $12 to $20"],
          },
        ],
      },
      {
        title: "Costa Rica General Info",
        sections: [
          {
            title: "Geography",
            content: [
              "Costa Rica, in Central America, is bordered by Nicaragua to the North and Panama to the South with the Pacific Ocean to the West and the Caribbean Sea to the East.",
              "Abolishing its army in 1948, it is the only Latin American country included in the list of the world's 22 older democracies. Costa Rica has consistently been among the top Latin American countries in the Human Development Index, ranked 62nd in the world in 2010, and is cited by the UNDP as one of the countries that has attained much higher human development than other countries at the same income levels. The country is ranked 3rd in the world, and 1st among the Americas, in terms of the 2010 Environmental Performance Index.",
              'In 2007 the Costa Rican government announced plans for Costa Rica to become the first carbon neutral country by 2021. According to the New Economics Foundation, Costa Rica ranks first in the Happy Planet Index and is the "greenest" country in the world.',
              "The highest regions of Costa Rica are found in the center of the country while the lowlands, which are more extensive and flat, extend to the Caribbean Coast on the northeast and to the northwest on the Pacific side. The Pacific Coast is more dissected, forming many bays, capes, cliffs and inlets. The Costa Rican mountain ranges form an independent group, part of the Central American massif. Three of these ranges run roughly from northwest to southeast, with a fourth (The Central Range) crossing them at the widest part of the country, thus forming a huge cross. Two volcanic ranges dominate northwestern Costa Rica. The first is the Guanacaste Volcanic Chain with its Orosi, Miravalles, Tenorio, Rincón de la Vieja and Arenal volcanos. Arenal offers amazing views, especially breathtaking when is clear, while Rincón de la Vieja's activity keeps natural mud pots in its foothills, bubbling permanently. The second range is the Tilarán Volcanic Chain in the northwest, formed by the hills of Abangares, Aguacate and Catedral. In the transverse chain of the central highlands, Poás, Barva, Irazú and Turrialba Volcanos are more accessible. Finally, to the south, are Costa Rica's highest mountains, those of the non-volcanic Talamanca Range. Of these, Chirripó Mountain is the most impressive, being the highest mountain in the country, 3,821 meters above sea level.",
              "The Caribbean coastline stretches for 212 km in a generally north-south direction. It is divided into two distinct sections; the San Juan River on the Nicaraguan border to the city of Limón and that which extends from the city of Limón to the Sixaola River on the border with Panamá. The first section consists of a long stretch of coastline that separates a series of estuary lagoons and waterways, fed by numerous rivers, from the sea. These make up the famous Tortuguero Canals, over 100 km long. These navigable canals are the habitat of seven species of turtles.",
              "The Pacific Coast extends over 1,016 km from one border to the other and offers a wide variety of geological features. From Salinas Bay in the north to the tip of Burica Point in the south, the Pacific Beaches of Costa Rica offer an almost infinite number of beaches for tourist enjoyment.",
              "The Santa Elena, Nicoya and Osa Peninsulas are the principal ones on the Pacific side.",
              "Two ferries cross the Nicoya Gulf, shortening the route to the isolated beaches of the southern tip of the Nicoya Peninsula. The shoreline of the gulf has many bays and promontories and is dotted with small islands.",
              "The Osa Peninsula, covered by great tracts of virgin forest with the most extensive variety of native species to be found in the country, lies on the western side of the Golfo Dulce.",
            ],
          },
        ],
      },
    ],
    photos: [
      require("@/assets/trips/volcanoToWaves/at1.jpg"),
      require("@/assets/trips/volcanoToWaves/at2.jpg"),
      require("@/assets/trips/volcanoToWaves/at3.jpg"),
      require("@/assets/trips/volcanoToWaves/at4.jpg"),
      require("@/assets/trips/volcanoToWaves/at7.jpg"),
      require("@/assets/trips/volcanoToWaves/at8.jpg"),
      require("@/assets/trips/volcanoToWaves/at9.jpg"),
      require("@/assets/trips/volcanoToWaves/at10.jpg"),
    ],
  },
  centralAmericaMultisport: {
    code: "centralAmericaMultisport",
    name: "Central America Multi-sport",
    headerSrc: require("@/assets/trips/cam/header.jpg"),
    overview: {
      length: "15 days",
      mapSrc: require("@/assets/trips/cam/map.jpg"),
      sellText:
        "Starting in Nicaragua, ending in Panama by whitewater raft, foot, jungle canopy zip line cables, bikes, sea kayak, plus snorkeling and visiting the Panama Canal. Explore three countries and two oceans.",
      overviewText: [
        "Do you want to stay active while exploring 3 Central American countries? The 15 day Multi-sport adventure gives you the best of the adventures of Central America with a fast pace, yet free time on beaches and towns to explore on your own.",
      ],
      highlights: [
        "Visit 3 countries on the same trip",
        "Peer down into the active Masaya Volcano",
        "Zip line through the rainforest",
        "Explore and hike Isla Ometepe",
        "Mountain Bike the Orosi Valley and around Lake Arenal",
        "Whitewater raft the magnificent Class IV Pacuare River",
        "Relax on the beaches of both the Caribbean and Pacific coasts",
        "Sea kayak on the Pacific Ocean",
        "Snorkel Costa Rica's best reef",
        "Visit Panama City",
      ],
      isPrivateItinerary: true,
      tripDates: [],
      note: "NOTE: All dates are guaranteed departures. We will open a new date with a minimum of 4 people.",
      priceSection: {
        primary: {
          price: "",
          pp: "",
          singleSupp: "",
          validity: "",
        },
        peakSeason: {
          priceText: "",
          price: "",
          pp: "",
          singleSupp: "",
          validity: "",
        },
        secondary: {
          price: "",
          pp: "",
          singleSupp: "",
          validity: "",
        },
      },
    },
    itinerary: [
      {
        day: 1,
        logos: ["arrival"],
        title: "Arrival in Nicaragua – Transfer to Granada",
        content: [
          "A Coast to Coast Adventures representative will meet your arrival in Managua to take you to your hotel in Granada, 45 minutes away.",
        ],
      },
      {
        day: 2,
        logos: ["trekking"],
        title: "Granada – Masaya Volcano Hike",
        content: [
          "We head up to the Masaya Volcano for a peak into the throat of this active volcano. Visitors are only permitted to be close to the edge of the volcano for a few minutes as the gases can be quite toxic. Just above the crater another dormant volcano offers great hiking with great views of Lake Masaya below. Then back to Granada for lunch and a free afternoon to explore Nicaragua's famed colonial city. (B)",
        ],
      },
      {
        day: 3,
        logos: ["canopy"],
        title: "Mombacho Volcano Zip Lining – Isla Ometepe",
        content: [
          "Our journey south through the last 3 countries of Central America begins today. The first stop is Mombacho Volcano for some adrenaline - zip lining through the forest, then visit a coffee plantation. We continue south to San Jorge to the ferry across Isla Ometepe, an hour on the fresh water lake (that was originally to be the trans-oceanic canal, until Panama spread rumors of Nicaragua's malaria problems). Ometepe is one of the world’s largest islands on a freshwater lake and home to two massive volcanoes - Conception and Madreas. End the day with a swim in refreshing natural springs of Ojo de Agua. (B, D)",
        ],
      },
      {
        day: 4,
        logos: ["pacific-palm"],
        title: "Isla Ometepe – San Juan del Sur Beach",
        content: [
          "On the way back to the ferry we make a stop at a small local museum that features an impressive collection of Nicaraguan money. The exhibit tells the history of Nicaragua through its money beginning with chocolate beans until the current printed and minted money. Less than an hour away, after crossing the ferry, we reach San Juan del Sur Beach on the Pacific. The rest of the afternoon is free to enjoy the beach and maybe even do a bit of body surfing. The town is quite lively so you will get some local flavor. (B)",
        ],
      },
      {
        day: 5,
        logos: ["biking"],
        title: "Border crossing into Costa Rica – Bike along Lake Arenal",
        content: [
          "Back on the road for our first border crossing entering Costa Rica, the second country of our journey. Like all border crossings in Latin America it may take a couple of hours, so relax and prepare to have your passport checked several times. As we cross into Costa Rica the scenery begins to change when we start climbing into the mountains towards the active Arenal Volcano. Lunch is in the town of Nuevo Arenal on the banks of lake Arenal, and a walk around town. Now for one of the nicest mountain bike rides in Costa Rica, along Lake Arenal, past the volcano and down to the town of La Fortuna (35 kms in length with some fun, hilly sections, culminating with a great downhill ride into town). Good news - the next 2 nights are at the same hotel in La Fortuna. (B)",
        ],
      },
      {
        day: 6,
        logos: ["volcano"],
        title: "Arenal Volcano – Free day for optional activities",
        content: [
          "This is a free day to do whatever you want and the La Fortuna area is the perfect place to continue with the Multi Sport theme. The Arenal Volcano area has some of the most incredible zip lining and canyoning tours in the world. Horseback riding, ATVing, sea kayaking on Lake Arenal, dip into the Fortuna Waterfalls natural swim hole, or canyoning --that's a combination of ropes, harnesses, and waterfalls. The best way to finish up the day is a soak in some natural hot springs at the base of the volcano. La Fortuna also has an impressive variety of restaurants. Talk with us about extra tours you can do, and pricing. (B)",
        ],
      },
      {
        day: 7,
        logos: ["biking"],
        title: "Orosi Valley – Bike to Turrialba",
        content: [
          "Continuing south we head for San Jose, the capital of Costa Rica. A quick drive through the city explains a lot about Costa Rica's dynamic economy, based on a well educated middle class. Then it's off to the coffee producing Orosi Valley. We unload the bikes again and begin a 50 km ride heading east through the Orosi and Reventazón Valleys. passing through coffee and sugarcane plantations and several small typically Costa Rican towns and villages. The final challenge is a hard climb up to a rustic mountain lodge offering some amazing views of the town of Turrialba and the valley below. (B, D)",
        ],
      },
      {
        day: 8,
        logos: ["rafting"],
        title: "Pacuare River – Whitewater Rafting day 1",
        content: [
          "Let's go Rafting! The Put-in is a half hour drive away so no need to rush this morning. Our two-day whitewater rafting adventure begins upon our arrival at the Pacuare River. We will run a total of 27 kilometers of Class III-IV rapids exploring the magnificent river valley. This afternoon we arrive at our comfortable riverside camp, “El Nido del Tigre.” Nestled between the sounds of the rainforest and the hum of the river, take time to explore the lush environment first hand, looking out for toucans overhead or simply relaxing in a hammock to the sounds of the river. (B, L, D)",
        ],
      },
      {
        day: 9,
        logos: ["rafting"],
        title: "Pacuare River – Whitewater Rafting day 2",
        content: [
          "Today the Pacuare takes us through virgin rainforest, cascading waterfalls and river carved canyons. We maneuver through the exhilarating rapids and point out the intricacies of the rainforest at the river’s edge. Along the way we take time out to do some hiking to spectacular waterfalls and have lunch on the shore. We take-out in the heart of banana plantation country at Finca Pacuarito, where we shower and then drive to Cahuita on the Caribbean Coast. (B, L)",
        ],
      },
      {
        day: 10,
        logos: ["caribbean-palm"],
        title: "Caribbean coast – Free day in Cahuita to visit National Park",
        content: [
          "Free time to enjoy the Caribbean Coast. Cahuita has a relaxed atmosphere with a mix of Latino, Afro-Caribbean and Bribri indigenous cultures. Locals usually speak their own language called Patois (pronounced “patua”) which is a mix of Afro-Caribbean English, Spanish and French. Cuhuita is a lively place for tourists to have a fun and relaxing experience. This area offers a lot of attractions and activities. Optional activities include: scuba diving, snorkeling, and a visit to Cahuita National Park or the Kekoldi Indian Reserve & waterfalls. (B)",
        ],
      },
      {
        day: 11,
        logos: ["pacific-palm"],
        title: "Border crossing to Panama – Transfer to Santa Catalina",
        content: [
          "Today is a long drive to Santa Catalina but broken up nicely with several stops along the way. The first stop is at the border where we spend an hour or so doing the typical border paper work. Once in Panama, we continue along the Caribbean coast, then turn inland and head up and over the continental divide to the Pacific side of the country. The views from the top are spectacular. Along the way we stop at a local swimming hole for a dip in a river followed by lunch. We arrive at Santa Catalina mid afternoon in time for a few hours on the beach and a chance to enjoy the sunset. (B, L)",
        ],
      },
      {
        day: 12,
        logos: ["kayaking"],
        title: "Santa Catalina Beach – Sea kayaking on the Pacific",
        content: [
          "For our second visit to the Pacific, this time in Panama, you can choose from some surfing on the beach break in front of the town or a paddle, on sea kayaks, across the bay to Santa Catalina island. Both are a blast and are a great way to spend a few hours being active. Santa Catalina is also a great place to relax and enjoy the laid back small beach town atmosphere. (B)",
        ],
      },
      {
        day: 13,
        logos: ["caribbean-palm"],
        title: "Beach time – Transfer to Panama City",
        content: [
          "The morning is free to enjoy the beach and then after lunch we press on to Panama City. We are staying in the center of the city so you can head out for dinner and the buoyant nightlife of this modern Latin city. For authentic Panamanian adventure hop onto a Chiva bus for a night of driving through the city, dancing on a moving bus, with hundreds of other buses, everyone pretty tipsy, but happy with wild Latin rhythm. Chiva buses -- also called Diablo Rojo (Red Devil) buses, defy decorum and blast their horns and turn up the music to DEAFEN and it's the best way to meet lots of Panamanians. (B)",
        ],
      },
      {
        day: 14,
        logos: ["caribbean-palm"],
        title: "Panama City – Free day to explore the city",
        content: [
          "A full day to explore Panama City. A visit to the Canal is a must and the best place is at the Miraflores Visitors Center at the Miraflores Locks, 20 minutes from downtown Panama City. The Center features a museum with interactive exhibits and a short film on the building of the Canal. An outside terrace gives you close-up views of ships transiting through the locks. Then a stroll through Casco Viejo, the Spanish colonial sector built in 1671 -- more than 340 years ago -- is a must-do. Casco Viejo showcases Panama's Spanish Colonial and Canal-Era history. A vibrant, colorful neighborhood, it's recently undergone a renaissance- restored hotels and restaurants in handsomely restored buildings are side by side with crumbling old buildings. One last chance for dinner with the group brings this journey to an end. (B)",
        ],
      },
      {
        day: 15,
        logos: ["departure"],
        title: "Departure from Panama City",
        content: [
          "Coast to Coast will take you to the Panama City airport for your return flight home. (B)",
        ],
      },
    ],
    needToKnow: [
      {
        title: "General Trip Information",
        sections: [
          {
            title: "Is this trip for me?",
            content: [
              "We divide our trips in 3 different levels (low, medium and high) to give our participants an idea of the fitness level they should have for the trip. This trip is rated as Medium.",
              "Medium: You need to be reasonably fit and have good stamina to take part in this trip. Some experience in mountain biking will make the trip more enjoyable. There are no specific requirements for whitewater rafting or sea kayaking but you will fully participate in paddling so fitness and stamina are a must.",
            ],
          },
          {
            title: "Daily routine",
            content: [
              "Our days usually start with fresh coffee and breakfast around 6:30. All packed and ready to go for our activity by 8:00. During the day you will have lots of time for pictures, water breaks and enjoying the scenery. The activities will be done at a comfortable pace for everyone, set by the guide. Around mid-day we will stop for lunch at a local restaurant or a picnic lunch made by the guides. The group will finish the day around 3:00 with plenty of time to rest. Dinner is usually served around 7:00 followed by a briefing for the next day.",
            ],
          },
          {
            title: "Price includes",
            content: [
              "Accommodation, transportation, airport transfers, meals where indicated, group equipment (bikes, rafts, camping gear, etc.), support vehicle and experienced, bilingual & fun guides throughout the trip.",
              "Note: Border fees are not included in the price.",
            ],
          },
          {
            title: "Group leader",
            content: [
              "Our Tour Leaders are a key part of any CtoC trip. All expertly trained and experienced in every field of activity, bilingual and qualified in first aid and/or Wilderness First responder. They are passionate about what they do and full of knowledge ready to share with you. They are responsible for ensuring the complete safety, well-being and enjoyment of our guests but retain a flexible and fun attitude.",
            ],
          },
          {
            title: "Guides Qualifications",
            content: [
              "All Coast to Coast Adventures’ guides have First AID, CPR and Swiftwater Rescue and Head Guides have Wilderness First Responder.",
            ],
          },
          {
            title: "Group size",
            content: ["Average between 6 and 12."],
          },
          {
            title: "Bikes Specifications",
            content: [
              "29ers Mountain Bike (Scott and Trek) or similar.",
              "Every bike has front suspension and disk brakes. Participants can bring their own pedals, seats and/or accessories if they want.",
            ],
          },
          {
            title: "Vaccinations & Protection",
            content: [
              "Vaccination requirements are subject to change and should be confirmed before departure. Consult your Doctor for the latest advice on different prophylaxis available against malaria.",
              "Please note: If you are entering from any of the following countries (Colombia, Brazil, Venezuela, Peru, Ecuador, Bolivia, French Guyana, Angola, Democratic Republic of the Congo, Gabon, Cameroon, Nigeria, Benin, Ghana, Burkina Faso, Liberia, Sierra Leone, Guinea, Gambia, and Sudan) are now required to produce an International Certificate of Vaccination or Prophylaxis documenting yellow fever vaccination before entry to Costa Rica will be granted. Certificates are valid 10 days after the date of vaccination.",
            ],
          },
          {
            title: "Visas",
            content: [
              "All countries require a valid passport (with a minimum 6 months validity). Contact your local embassy, or consulate for the most up-to-date visa requirements. IT'S YOUR RESPONSIBILITY TO HAVE THE CORRECT TRAVEL DOCUMENTATION.",
            ],
          },
          {
            title: "Laundry",
            content: [
              "Having your clothes clean and dry while you travel is always a challenge. Laundry services are offered by some of our hotels for a charge. There will be times when you may want to do your own laundry so we suggest you bring non-polluting/biodegradable soap.",
            ],
          },
          {
            title: "Travel Insurance",
            content: [
              "CtoC Adventures requires all customers to have valid Travel Insurance.",
            ],
          },
        ],
      },
      {
        title: "Accommodations, Meals and Transportation",
        sections: [
          {
            title: "Accommodations",
            content: [
              "12 nights in Hotels",
              "1 night in set campsite",
              "1 night in local cabin",
            ],
          },
          {
            title: "Transportation",
            content: [
              "All transportation according to the itinerary is included, starting with the transfer in, along the trip with transportation from one area to the other, as well with transportation during the activities. Finishing with the transfer out.",
            ],
          },
          {
            title: "Meals",
            content: [
              "Eating is one of the wonderful experiences when traveling in Costa Rica. Included meals are a combination of restaurants, local food and delicious meals prepared by the tour guides.",
            ],
          },
          {
            title: "Meals Included",
            content: ["Breakfasts 14", "Lunches 5", "Dinner 1"],
          },
        ],
      },
      {
        title: "Gear / Kit Needed",
        sections: [
          {
            title: "Luggage Carrier",
            content: [
              "Duffle Bag",
              "Daypack (approx. 20-35 L)",
              "Daypack rain cover",
            ],
          },
          {
            title: "Upper Body",
            content: [
              "Shirts (poly-pro outer wear (for quick drying)",
              "Short sleeve and light colored long sleeve for sun protection)",
              "Lightweight rain jacket (Gortex, nylon or similar)",
              "Medium weight wool or fleece over-sweater",
              "Lightweight thermal underwear top (polypro/wool)",
            ],
          },
          {
            title: "Lower Body",
            content: [
              "Lightweight comfortable pants",
              "Shorts (quick dry)",
              "Lightweight rain pants",
            ],
          },
          {
            title: "Head",
            content: [
              "Sun/rain hat with brim (preferably 360˚ brim)",
              "Bandana",
            ],
          },
          {
            title: "Footwear",
            content: [
              "Lightweight adventure shoes or hiking boots",
              "Sandals with SNUG straps (Teva type)",
              "Lightweight fast drying hiking socks",
            ],
          },
          {
            title: "Personal Gear",
            content: [
              "Sunglasses (UVA and UVB resistant)",
              "Keeper straps for sunglasses",
              "1 headlamp (long lasting LED bulbs are the best option)",
              "Extra batteries (for headlamp, camera, etc)",
              "Additional small flashlight",
              "2-3 one quart, durable water bottles",
              "Prescription glasses or contacts (bring an extra pair of glasses)",
              "1 copy of your passport",
              "Insect repellent",
              "Waterproof sunscreen 30+ SPF",
              "Gallon & quart size Ziploc bags",
              "Towel",
            ],
          },
          {
            title: "Optional Gear",
            content: [
              "Dry bag",
              "Camera w/ waterproof camera bag",
              "Deck of cards",
              "Binoculars",
              "Sarongs (works as a towel, or quick cover-up)",
              "Writing materials (pen, pencil, notebook…)",
              "USB memory card (to share pictures with other participants)",
            ],
          },
          {
            title: "Biking",
            content: [
              "Bike shorts with padded seat",
              "Bike water bottle",
              "Bike helmet",
              "Bike gloves (can also be used for paddling)",
              "Bike shirts (optional)",
            ],
          },
          {
            title: "Notes",
            content: [
              "No sleeping gear needed for overnight stays at El Nido del Tigre and Finca Pacuarito",
              "Feel free to bring your own pedals and seat if you want",
              "We supply the basic equipment needed for the activities. We asked our customers to bring their own cycling helmet.",
            ],
          },
        ],
      },
      {
        title: "Money Related",
        sections: [
          {
            title: "Money Exchange",
            content: [
              "A combination of US dollars and credit/debit cards is ideal, it’s best to bring notes in good condition and lower than 100USD. Travelers cheques are not a good idea. Cards are widely accepted but little stores or street sellers may only take local cash. There are ATMS along the way. Check with your bank to make sure your card will work internationally.",
            ],
          },
          {
            title: "Spending Money",
            content: [
              "How much you need will depend on your spending habits. You will need to pay for some meals, drinks, souvenirs and tipping. Here are a few things to keep in mind when you are deciding on the amount of spending money to take on your trip.",
            ],
          },
          {
            title: "Departure Tax",
            content: [
              "The Departure Tax in Panama is $40, this is usually included in your airfare.",
            ],
          },
          {
            title: "Tipping",
            content: [
              "It is customary in Latin America to tip service providers such as waiters, at approximately 10%, depending on the service. Also if you felt your local guides and drivers did an outstanding job, tipping is appreciated.",
            ],
          },
          {
            title: "Meal Costs",
            content: [
              "Nicaragua:",
              "Lunch: $5 to $8",
              "Dinner: $8 to $12",
              "Costa Rica:",
              "Lunch: $10 to $12",
              "Dinner: $12 to $20",
              "Panama:",
              "Lunch: $7 to $10",
              "Dinner: $10 to $15",
            ],
          },
        ],
      },
      {
        title: "Nicaragua, Costa Rica and Panama Quick Info",
        sections: [
          {
            title: "Nicaragua",
            content: [
              "Population: 5,891,199",
              "Size of Country: 130,373 sq km",
              "Major Language: Spanish",
              "Religion: Roman Catholic",
              "Time Difference to GMT: -6",
            ],
          },
          {
            title: "Costa Rica",
            content: [
              "Population: 4,608,426",
              "Size of Country: 51,100 sq km",
              "Major Language: Spanish",
              "Religion: Christian, Roman Catholic",
              "Time Difference to GMT: -6",
            ],
          },
          {
            title: "Panama",
            content: [
              "Population: 3,405,813",
              "Size of Country: 75,517 sq km",
              "Major Language: Spanish",
              "Religion: Roman Catholic",
              "Time Difference to GMT: -5",
            ],
          },
        ],
      },
    ],
    photos: [
      require("@/assets/trips/cam/cam1.jpg"),
      require("@/assets/trips/cam/cam2.jpg"),
      require("@/assets/trips/cam/cam3.jpg"),
      require("@/assets/trips/cam/cam4.jpg"),
      require("@/assets/trips/cam/cam5.jpg"),
      require("@/assets/trips/cam/cam6.jpg"),
      require("@/assets/trips/cam/cam7.jpg"),
    ],
  },
  activeFamilyAdventure: {
    code: "activeFamilyAdventure",
    name: "Active Family Adventure",
    headerSrc: require("@/assets/trips/activeFamilyAdventure/header.jpg"),
    overview: {
      length: "8 days",
      mapSrc: require("@/assets/trips/activeFamilyAdventure/map.jpg"),
      sellText:
        "Majestic Arenal Volcano, unspoilt Caribbean beaches and the Pacuare, Costa Rica's best whitewater rafting. Our Family adventure trip is authentic, energetic, and diverse.",
      overviewText: [
        "Parents, grandparents, and adventurous kids ask for this special adventure combination. The major highlights of Costa Rica -- volcano and beach --with 2 days on the Pacuare River - the river that made Costa Rica adventure famous. Minimum age for the river is 14.",
        "In addition to a world class raft adventure, hike, then zip from one tree platform to the next - a pure adrenaline experience. The Arenal Volcano area is full of interesting options for active people -- hike the slopes of the volcano, by horseback to waterfalls, hanging bridges, hot rod an ATV, waterfall rappelling , or just relax in natural hot springs.",
        "The Pacuare River is two days of outstanding whitewater, with overnight in El Nido del Tigre, Coast to Coast's comfortable private camp on the riverbank. It's real immersion in the jungle.",
        "If you like the trip but do not want to raft it is possible to hike in and out of El Nido del Tigre. We aim to design the trip to suit your family so do not hesitate to tell us your likes and dislikes.",
      ],
      promotionText: "Book 4 in quadruple occupancy and get 10% off",
      highlights: [
        "Hike around the magnificent Arenal Volcano",
        "Soak in primal hot springs",
        "Horseback (optional) or hike to La Fortuna’s Waterfall",
        "Zip line adrenaline in the rain forest",
        "Whitewater raft the famous Pacuare River",
        "White sand beaches and wildlife of Cahuita National Park",
      ],
      isPrivateItinerary: true,
      tripDates: [],
      note: "NOTE: All dates are guaranteed departures. We will open a new date with a minimum of 4 people.",
      priceSection: {
        primary: {
          price: "$1,245 USD",
          pp: "per person",
          singleSupp: "Single Supplement $330",
          validity: "",
        },
        peakSeason: {
          priceText: "PEAK SEASON",
          price: "$1,385 USD",
          pp: "per person",
          singleSupp: "Single Supplement $430",
          validity: "(Christmas & Easter)",
        },
        secondary: {
          price: "",
          pp: "",
          singleSupp: "",
          validity: "",
        },
      },
    },
    itinerary: [
      {
        day: 1,
        logos: ["arrival"],
        title: "Arrival in Costa Rica – Hotel in San Jose",
        content: [
          "A Coast to Coast representative will meet you at the Juan Santamaria International Airport in San Jose and give you vouchers and trip instructions then drive you to a cozy hotel in the capital.",
        ],
      },
      {
        day: 2,
        logos: ["trekking"],
        title: "Arenal Volcano – Hiking & Hot Springs",
        content: [
          "Today after breakfast you will go to La Fortuna, the closest town to the Arenal volcano (3.5 hours). Between 3 and 4 in the afternoon be ready to leave your hotel with the tour company to hike with a naturalist guide on trails to spot a variety of diverse flora and fauna -- monkeys, toucans, wild turkeys, sloth, birds, lizards, iguanas, snakes, and interesting insects. The flora here is different because the volcano produces such a different type of soil. Arriving at the observation point in time for sunset means a fantastic view of this majestic volcano against the backdrop of the setting sun. You continue your way down to a natural Hot Springs for a relaxing bath and dinner. (B, D)",
        ],
      },
      {
        day: 3,
        logos: ["canopy", "trekking"],
        title:
          "Arenal Volcano – Horseback ride (optional) or car to Fortuna Waterfall, then Canopy Tour (Zip lines)",
        content: [
          "To La Fortuna’s Waterfall -- choose:",
          "a) Horseback Riding – which is actually a combination of horseback riding and hiking in this beautiful scenery. The horses can take you all the way up to the top of the waterfall through green areas of pastures, forest and high hills. At the top we walk on foot to the base of the 200 ft./ 65m waterfall, then swim in the natural pools surrounding the cascading waterfall before the return hike uphill. Back on our horses for three river crossings and through cattle farms.",
          "b) By car to the top of the waterfall entrance, then hike down, swim, and back up.",
          "Then after lunch it is a zipline tour in the canopy of the forest in the impressive canyon of the Arenal River. With a bit of luck you'll spot three kinds of monkeys, toucans, hummingbirds and sloths. (B)",
        ],
      },
      {
        day: 4,
        logos: ["rafting"],
        title: "Pacuare River – Rafting class II-III (10km)",
        content: [
          "Leave Fortuna early for 3 hour drive to the “put in” for the Pacuare River, two days of high action white water rafting adventure. We will run a total of 22 miles/35 kilometers of class III-IV rapids in a nearly untouched jungle valley. Overnight is in El Nido del Tigre, Coast to Coast's private and comfortable camp, nestled between the rainforest and the river. The sounds from both forest and water are as pure and wild as the jungle around us. From camp take a slow hike to secluded rainforest, waterfalls and swimming holes. Explore the lush environment -- toucans and oropendolas, sloth, rainforest turtles. Or relax in a hammock to the sounds of the river. (B, L, D)",
        ],
      },
      {
        day: 5,
        logos: ["rafting"],
        title: "Pacuare River – Rafting class III-IV (25km)",
        content: [
          "The Pacuare carries us through virgin rainforest, cascading waterfalls and river carved canyons. We maneuver through the exhilarating rapids, aware that only the jungle, and raw nature, surrounds us. Along the way we stop to hike spectacular waterfalls and eat lunch on the shore. From the take-out at Finca Pacuarito, we drive to one of the small beach towns on the Caribbean coast. (B, L)",
        ],
      },
      {
        day: 6,
        logos: ["caribbean-palm"],
        title: "Caribbean coast – Free day on the beach",
        content: [
          "Free time to enjoy the crystal clear blue water of the Caribbean coast. The Caribbean is very different from the rest of Costa Rica, with a mix of Latino, Afro-Caribbean and Bribri indigenous cultures. Locals often speak Patois, a mixture of Afro-Caribbean (Jamaican) English, Spanish and French. Cahuita is well known for the coastal national park, with hundreds of species and tropical flora, all Costa Rica's monkey species, birds-both year round beauties like toucans and macaws to migrating species, plus Costa Rica's best coral reef. On your own you can go on snorkeling, or kayak uninhabited estuaries for the best of riparian nature spotting. 15 miles south of Cahuita is Puerto Viejo, Reggae capital and restaurant styles from all over the world. Indeed, Costa Rica's best restaurants are here.. French and Italian, Sushi and Vegan, Indonesian, and of course seafood. Optional activities you can sign up for include: scuba diving, snorkeling, chocolate production (with samples), Jaguar rescue center and other great animal preserves, or the Kekoldi Indian Reserve & waterfalls. Talk with us about extra tours you can do, and pricing. (B)",
        ],
      },
      {
        day: 7,
        logos: ["caribbean-palm"],
        title: "Caribbean coast – Free morning / Afternoon back to San Jose",
        content: [
          "Your final morning on the beach. After lunch we head back to San José, passing through Braulio Carrillo National Park with breathtaking mountains and noisy rainforest. (B)",
        ],
      },
      {
        day: 8,
        logos: ["departure"],
        title: "Departure",
        content: [
          "A Coast to Coast representative will pick you up at your hotel for your homeward flight. Hard to leave, but you'll be back! (B)",
        ],
      },
    ],
    needToKnow: [
      {
        title: "General Trip Information",
        sections: [
          {
            title: "Is this trip for me?",
            content: [
              "We divide our trips in 3 different levels (low ,medium and high) to give our participants an idea of the fitness level they should have for the trip. This trip is rated as Low",
              "Low: A trip designed for everyone! We ask that you have a healthy spirit of adventure and good general fitness. There are no specific requirements for whitewater rafting but you will fully participate in paddling.",
            ],
          },
          {
            title: "Daily routine",
            content: [
              "Upon arrival you will receive a package containing pick up times and vouchers for all activities, after each day tour you will have the opportunity to visit each destination and have a free afternoon to rest for the next days adventure.",
            ],
          },
          {
            title: "Price includes",
            content: [
              "Accommodation, airport transfers, meals where indicated, group equipment (rafts, camping gear, etc.) & fun guides for each of the activities mentioned.",
            ],
          },
          {
            title: "Guides Qualifications",
            content: [
              "All Coast to Coast Adventures’ guides have First AID, CPR and Swiftwater Rescue and Head Guides have Wilderness First Responder.",
            ],
          },
          {
            title: "Group size",
            content: ["Average between 4 and 10 passengers, plus guides."],
          },
          {
            title: "Vaccinations & Protection",
            content: [
              "Visa and vaccination requirements are subject to change and should be confirmed before departure. Consult your Doctor for latest advice on different prophylaxis available against malaria",
            ],
          },
          {
            title: "Laundry",
            content: [
              "Having your cloth clean and dry while you travel is always a challenge. Laundry services are offered by some of our hotels for a charge. There will be times when you may want to do your own laundry so we suggest you bring non-polluting/biodegradable soap.",
            ],
          },
          {
            title: "Travel Insurance",
            content: [
              "CtoC Adventures requires all customers to have valid Travel Insurance.",
            ],
          },
        ],
      },
      {
        title: "Accommodations, Meals and Transportation",
        sections: [
          {
            title: "Accommodations",
            content: ["6 nights in Hotels", "1 night in set campsite"],
          },
          {
            title: "Transportation",
            content: [
              "All transportation according to the itinerary is included, starting with the transfer in, along the trip with transportation from one area to the other, as well with transportation during the activities. Finishing with the transfer out.",
            ],
          },
          {
            title: "Meals",
            content: [
              "Eating is one of the wonderful experiences when travelling in Costa Rica. Included meals are a combination of restaurants, local food and delicious meals prepared by the tour guides",
            ],
          },
          {
            title: "Meals Included",
            content: ["Breakfasts 7", "Lunches 2", "Dinners 2"],
          },
        ],
      },
      {
        title: "Gear / Kit Needed",
        sections: [
          {
            title: "Luggage Carrier",
            content: [
              "Duffle Bag",
              "Daypack (approx. 20-35 L)",
              "Daypack rain cover",
            ],
          },
          {
            title: "Upper Body",
            content: [
              "Shirts (poly-pro outer wear (for quick drying)",
              "Short sleeve and light colored long sleeve for sun protection)",
              "Lightweight rain jacket (Gortex, nylon or similar)",
              "Medium weight wool or fleece over-sweater",
              "Lightweight thermal underwear top (polypro/wool)",
            ],
          },
          {
            title: "Lower Body",
            content: [
              "Lightweight comfortable pants",
              "Shorts (quick dry)",
              "Lightweight rain pants",
            ],
          },
          {
            title: "Head",
            content: [
              "Sun/rain hat with brim (preferably 360˚ brim)",
              "Bandana",
            ],
          },
          {
            title: "Footwear",
            content: [
              "Lightweight adventure shoes or hiking boots",
              "Sandals with SNUG straps (Teva type)",
              "Lightweight fast drying hiking socks",
            ],
          },
          {
            title: "Personal Gear",
            content: [
              "Sunglasses (UVA and UVB resistant)",
              "Keeper straps for sunglasses",
              "1 headlamp (long lasting LED bulbs are the best option)",
              "Extra batteries (for headlamp, camera, etc)",
              "Additional small flashlight",
              "2-3 one quart, durable water bottles",
              "Prescription glasses or contacts (bring an extra pair of glasses)",
              "1 copy of your passport",
              "Insect repellent",
              "Waterproof sunscreen 30+ SPF",
              "Gallon & quart size Ziploc bags",
              "Towel",
            ],
          },
          {
            title: "Optional Gear",
            content: [
              "Dry bag",
              "Camera w/ waterproof camera bag",
              "Deck of cards",
              "Binoculars",
              "Sarongs (works as a towel, or quick cover-up)",
              "Writing materials (pen, pencil, notebook…)",
              "USB memory card (to share pictures with other participants)",
            ],
          },
          {
            title: "Note",
            content: [
              "No sleeping gear needed for overnight stays at El Nido del Tigre and Finca Pacuarito",
            ],
          },
        ],
      },
      {
        title: "Money Related",
        sections: [
          {
            title: "Money Exchange",
            content: [
              "A combination of US dollars and credit/debit cards is ideal, it’s best to bring notes in good condition and lower than 100USD. Travelers cheques are not a good idea. Cards are widely accepted but little stores or street sellers may only take local cash. There are ATMS along the way. Check with your bank to make sure your card will work internationally.",
            ],
          },
          {
            title: "Spending Money",
            content: [
              "How much you need will depend on your spending habits. You will need to pay for some meals, drinks, souvenirs and tipping. Here are a few things to keep in mind when you are deciding on the amount of spending money to take on your trip.",
            ],
          },
          {
            title: "Departure Tax",
            content: [
              "The Departure Tax in Costa Rica is $29. This needs to be paid at the airport using US or local cash or any major credit card. Pay this tax before going to the airline counter. Check with your airline if this fee is already included on your flight ticket.",
            ],
          },
          {
            title: "Tipping",
            content: [
              "It is customary in Latin America to tip service providers such as waiters, at approximately 10%, depending on the service. Also if you felt your local guides and drivers did an outstanding job, tipping is appreciated.",
            ],
          },
          {
            title: "Meal Costs",
            content: ["Lunch: $10 to $12", "Dinner: $12 to $20"],
          },
        ],
      },
      {
        title: "Costa Rica General Info",
        sections: [
          {
            title: "Geography",
            content: [
              "Costa Rica, in Central America, is bordered by Nicaragua to the North and Panama to the South with the Pacific Ocean to the West and the Caribbean Sea to the East.",
              "Abolishing its army in 1948, it is the only Latin American country included in the list of the world's 22 older democracies. Costa Rica has consistently been among the top Latin American countries in the Human Development Index, ranked 62nd in the world in 2010, and is cited by the UNDP as one of the countries that has attained much higher human development than other countries at the same income levels. The country is ranked 3rd in the world, and 1st among the Americas, in terms of the 2010 Environmental Performance Index.",
              "In 2007 the Costa Rican government announced plans for Costa Rica to become the first carbon neutral country by 2021. According to the New Economics Foundation, Costa Rica ranks first in the Happy Planet Index and is the 'greenest' country in the world.",
              "The highest regions of Costa Rica are found in the center of the country while the lowlands, which are more extensive and flat, extend to the Caribbean Coast on the northeast and to the northwest on the Pacific side. The Pacific Coast is more dissected, forming many bays, capes, cliffs and inlets. The Costa Rican mountain ranges form an independent group, part of the Central American massif. Three of these ranges run roughly from northwest to southeast, with a fourth (The Central Range) crossing them at the widest part of the country, thus forming a huge cross. Two volcanic ranges dominate northwestern Costa Rica. The first is the Guanacaste Volcanic Chain with its Orosi, Miravalles, Tenorio, Rincón de la Vieja and Arenal volcanos. Arenal offers amazing views, especially breathtaking when is clear, while Rincón de la Vieja's activity keeps natural mud pots in its foothills, bubbling permanently. The second range is the Tilarán Volcanic Chain in the northwest, formed by the hills of Abangares, Aguacate and Catedral. In the transverse chain of the central highlands, Poás, Barva, Irazú and Turrialba Volcanos are more accessible. Finally, to the south, are Costa Rica's highest mountains, those of the non-volcanic Talamanca Range. Of these, Chirripó Mountain is the most impressive, being the highest mountain in the country, 3,821 meters above sea level.",
              "The Caribbean coastline stretches for 212 km in a generally north-south direction. It is divided into two distinct sections; the San Juan River on the Nicaraguan border to the city of Limón and that which extends from the city of Limón to the Sixaola River on the border with Panamá. The first section consists of a long stretch of coastline that separates a series of estuary lagoons and waterways, fed by numerous rivers, from the sea. These make up the famous Tortuguero Canals, over 100 km long. These navigable canals are the habitat of seven species of turtles.",
              "The Pacific Coast extends over 1,016 km from one border to the other and offers a wide variety of geological features. From Salinas Bay in the north to the tip of Burica Point in the south, the Pacific Beaches of Costa Rica offer an almost infinite number of beaches for tourist enjoyment.",
              "The Santa Elena, Nicoya and Osa Peninsulas are the principal ones on the Pacific side.",
              "Two ferries cross the Nicoya Gulf, shortening the route to the isolated beaches of the southern tip of the Nicoya Peninsula. The shoreline of the gulf has many bays and promontories and is dotted with small islands.",
              "The Osa Peninsula, covered by great tracts of virgin forest with the most extensive variety of native species to be found in the country, lies on the western side of the Golfo Dulce.",
            ],
          },
        ],
      },
    ],
    photos: [
      require("@/assets/trips/activeFamilyAdventure/afa1.jpg"),
      require("@/assets/trips/activeFamilyAdventure/afa2.jpg"),
      require("@/assets/trips/activeFamilyAdventure/afa3.jpg"),
      require("@/assets/trips/activeFamilyAdventure/afa4.jpg"),
      require("@/assets/trips/activeFamilyAdventure/afa5.jpg"),
      require("@/assets/trips/activeFamilyAdventure/afa6.jpg"),
    ],
  },
  volunteeringFamilyAdventure: {
    code: "volunteeringFamilyAdventure",
    name: "Volunteering Family Adventure",
    headerSrc: require("@/assets/trips/volunteeringFamilyAdventure/header.jpg"),
    overview: {
      length: "10 days",
      mapSrc: require("@/assets/trips/volunteeringFamilyAdventure/map.jpg"),
      sellText:
        "To have the opportunity to experience another culture at same time as serving in a meaningful way. It is fun, it is adventurous, and it offers a rare window into the lives, challenges and true beauties of life outside one’s comfort zone. Combined with the majestic Arenal Volcano and the beauty of Manuel Antonio National Park and beaches.",
      overviewText: [
        "Responsible tourism is one of the best ways to travel because it introduces you to real local culture while helping others in need. Imagine how good it would feel knowing that you made a difference in the environment or in someone’s lives. On this adventure you will be a helping hand in a nursing home, elementary school, and rural farm; support the Maleku indigenous tribe; and help sea turtles during their nesting process! You will also have plenty of time for rest, play, and adventure in both the mountainous rainforest and at the stunning beach.",
      ],
      highlights: [
        "Volunteering work with animals at Proyecto Asis",
        "Hot springs in the Arenal Volcano area.",
        "Horseback ride (optional) and La Fortuna’s Waterfall Hike.",
        "Zip lining in the rain forest",
        "Sandy beaches and wildlife of the Manuel Antonio National Park.",
      ],
      isPrivateItinerary: true,
      tripDates: [],
      note: "NOTE: All dates are guaranteed departures. We will open a new date with a minimum of 4 people.",
      priceSection: {
        primary: {
          price: "$1,520 USD",
          pp: "per person",
          singleSupp: "Single Supplement $420",
          validity: "",
        },
        peakSeason: {
          priceText: "PEAK SEASON",
          price: "$1,685 USD",
          pp: "per person",
          singleSupp: "Single Supplement $495",
          validity: "(Christmas & Easter)",
        },
        secondary: {
          price: "",
          pp: "",
          singleSupp: "",
          validity: "",
        },
      },
    },
    itinerary: [
      {
        day: "1",
        logos: ["arrival"],
        title: "Arrival in Costa Rica – Hotel in San Jose",
        content: [
          "You will be met at the airport and transferred to your hotel in San Jose.",
        ],
      },
      {
        day: "2",
        title: "Javillo – Volunteering",
        content: [
          "Today after breakfast you will be transferred to the Proyecto Asis near the town of Javillo (3 hours). Proyecto Asis is an ecological reserve focused on educating and promoting environmental conservation. After our arrival in Javillo, we will be accommodated with local families in the community and enjoy a delicious homemade lunch before being transferred to the project for an introduction tour. The tour takes about 2 hours and is in the company of a Tour Guide. You will learn everything about the wild animals, the reason why they are here at the rescue center, and what their rehabilitation process is. Also, bearing in mind important facts such as social behavior, type of diet, and physical conditions, you will have the chance to understand which animals are able to be released back into the wild. One important part of this activity is the discussion about the traffic of wild animals around the world, which is affecting many species and putting them endangered. (B, L, D)",
        ],
      },
      {
        day: "3-4",
        title: "Javillo – Volunteering",
        content: [
          "During these two full days of volunteering work at the Project, you will be able to feed the animals, clean enclosures and cages, make toys for the animals, and work on some maintenance projects. (B, L, D)",
        ],
      },
      {
        day: "5",
        logos: ["volcano"],
        title:
          "Arenal Volcano – Volunteering in the morning / Hot Springs at night",
        content: [
          "After another morning of volunteering work, we will say goodbye to our new friends before beginning our short transfer to La Fortuna, the closest town to Arenal volcano (40 mins). The afternoon may be spent exploring the town of La Fortuna or relaxing in your hotel. In the evening, you will be transferred to one of the amazing and relaxing thermal waters located in the area, where the entrance and a delicious dinner will be included. (B, L, D)",
        ],
      },
      {
        day: "6",
        logos: ["volcano", "trekking", "canopy"],
        title: "Arenal Volcano – Hiking / Zip lining",
        content: [
          "Today after breakfast, you will be transferred by car to the entrance of the park to access La Fortuna’s Waterfall, where we will walk along a trail to the base of a 65m waterfall. We take some time in the natural pools surrounding the cascading waterfall before the return hike to the main entrance. One of the main attractions in Fortuna is to take a zipline tour in the canopy of the forest, so you will enjoy different platforms where you will be able to see the impressive canyon of the Arenal River and the extraordinary flora and fauna of the rain forest. If you are lucky, you will have the opportunity to see the three kinds of monkeys that live in this zone, toucans, hummingbirds, and sloths. (B)",
        ],
      },
      {
        day: "7-8",
        logos: ["pacific-palm"],
        title: "Manuel Antonio – Free afternoon",
        content: [
          "The Pacific Coast of Costa Rica is a treasure chest of natural beauty. Rainforest dramatically meets beach and the sea and Manuel Antonio is a great place to relax and enjoy the beach or the National Park. You will often be awakened by the local monkeys – howler, white-faced, and squirrel are the most common in the area, as well as iguanas and land crabs. (B)",
        ],
      },
      {
        day: "9",
        logos: ["pacific-palm"],
        title:
          "Manuel Antonio – Free morning / Afternoon transfer back to San Jose",
        content: [
          "The morning is free to spend a few more hours enjoying the beach. After lunch, you will be transferred back to San Jose (30-minute local flight is also an option at an additional charge). The evening may be spent taking in the sights and sounds of San Jose. (B)",
        ],
      },
      {
        day: "10",
        logos: ["departure"],
        title: "Departure",
        content: [
          "You will be picked up at your hotel in time for your departure from the airport. (B)",
        ],
      },
    ],
    needToKnow: [
      {
        title: "General Trip Information",
        sections: [
          {
            title: "Is this trip for me?",
            content: [
              "We divide our trips in 3 different levels (low, medium and high) to give our participants an idea of the fitness level they should have for the trip. This trip is rated as Low",
              "Low: A trip designed for everyone! We ask that you have a healthy spirit of adventure and good general fitness. There are no specific requirements for whitewater rafting but you will fully participate in paddling.",
            ],
          },
          {
            title: "Daily routine",
            content: [
              "Upon arrival you will receive a package containing pick up times and vouchers for all activities, after each day tour you will have the opportunity to visit each destination and have a free afternoon to rest for the next day of adventure.",
            ],
          },
          {
            title: "Price includes",
            content: [
              "Accommodation, airport transfers, meals where indicated, group equipment (rafts, camping gear, etc.) & fun guides for each of the activities mentioned.",
            ],
          },
          {
            title: "Guides Qualifications",
            content: [
              "All Coast to Coast Adventures’ guides have First AID, CPR and Swiftwater Rescue and Head Guides have Wilderness First Responder.",
            ],
          },
          {
            title: "Group size",
            content: ["Average between 4 and 10 passengers, plus guides."],
          },
          {
            title: "Vaccinations & Protection",
            content: [
              "Visa and vaccination requirements are subject to change and should be confirmed before departure. Consult your Doctor for latest advice on different prophylaxis available against malaria",
            ],
          },
          {
            title: "Laundry",
            content: [
              "Having your cloth clean and dry while you travel is always a challenge. Laundry services are offered by some of our hotels for a charge. There will be times when you may want to do your own laundry so we suggest you bring non-polluting/biodegradable soap.",
            ],
          },
          {
            title: "Travel Insurance",
            content: [
              "CtoC Adventures requires all customers to have valid Travel Insurance.",
            ],
          },
        ],
      },
      {
        title: "Accommodations, Meals and Transportation",
        sections: [
          {
            title: "Accommodations",
            content: ["6 nights in Hotels", "3 nights family stay"],
          },
          {
            title: "Transportation",
            content: [
              "All transportation according to the itinerary is included, starting with the transfer in, along the trip with transportation from one area to the other, as well with transportation during the activities. Finishing with the transfer out.",
            ],
          },
          {
            title: "Meals",
            content: [
              "Eating is one of the wonderful experiences when travelling in Costa Rica. Included meals are a combination of restaurants, local food and delicious meals prepared by the tour guides",
            ],
          },
          {
            title: "Meals Included",
            content: ["Breakfasts 9", "Lunches 4", "Dinners 4"],
          },
        ],
      },
      {
        title: "Gear / Kit Needed",
        sections: [
          {
            title: "Luggage Carrier",
            content: [
              "Duffle Bag",
              "Daypack (approx. 20-35 L)",
              "Daypack rain cover",
            ],
          },
          {
            title: "Upper Body",
            content: [
              "Shirts (poly-pro outer wear (for quick drying)",
              "Short sleeve and light colored long sleeve for sun protection)",
              "Lightweight rain jacket (Gortex, nylon or similar)",
              "Medium weight wool or fleece over-sweater",
              "Lightweight thermal underwear top (polypro/wool)",
            ],
          },
          {
            title: "Lower Body",
            content: [
              "Lightweight comfortable pants",
              "Shorts (quick dry)",
              "Lightweight rain pants",
            ],
          },
          {
            title: "Head",
            content: [
              "Sun/rain hat with brim (preferably 360˚ brim)",
              "Bandana",
            ],
          },
          {
            title: "Footwear",
            content: [
              "Lightweight adventure shoes or hiking boots",
              "Sandals with SNUG straps (Teva type)",
              "Lightweight fast drying hiking socks",
            ],
          },
          {
            title: "Personal Gear",
            content: [
              "Sunglasses (UVA and UVB resistant)",
              "Keeper straps for sunglasses",
              "1 headlamp (long lasting LED bulbs are the best option)",
              "Extra batteries (for headlamp, camera, etc)",
              "Additional small flashlight",
              "2-3 one quart, durable water bottles",
              "Prescription glasses or contacts (bring an extra pair of glasses)",
              "1 copy of your passport",
              "Insect repellent",
              "Waterproof sunscreen 30+ SPF",
              "Gallon & quart size Ziploc bags",
              "Towel",
            ],
          },
          {
            title: "Optional Gear",
            content: [
              "Dry bag",
              "Camera w/ waterproof camera bag",
              "Deck of cards",
              "Binoculars",
              "Sarongs (works as a towel, or quick cover-up)",
              "Writing materials (pen, pencil, notebook…)",
              "USB memory card (to share pictures with other participants)",
            ],
          },
        ],
      },
      {
        title: "Money Related",
        sections: [
          {
            title: "Money Exchange",
            content: [
              "A combination of US dollars and credit/debit cards is ideal, it’s best to bring notes in good condition and lower than 100USD. Travelers cheques are not a good idea. Cards are widely accepted but little stores or street sellers may only take local cash. There are ATMS along the way. Check with your bank to make sure your card will work internationally.",
            ],
          },
          {
            title: "Spending Money",
            content: [
              "How much you need will depend on your spending habits. You will need to pay for some meals, drinks, souvenirs and tipping. Here are a few things to keep in mind when you are deciding on the amount of spending money to take on your trip.",
            ],
          },
          {
            title: "Departure Tax",
            content: [
              "The Departure Tax in Costa Rica is $29. This needs to be paid at the airport using US or local cash or any major credit card. Pay this tax before going to the airline counter. Check with your airline if this fee is already included on your flight ticket.",
            ],
          },
          {
            title: "Tipping",
            content: [
              "It is customary in Latin America to tip service providers such as waiters, at approximately 10%, depending on the service. Also if you felt your local guides and drivers did an outstanding job, tipping is appreciated.",
            ],
          },
          {
            title: "Meal Costs",
            content: ["Lunch: $10 to $12", "Dinner: $12 to $20"],
          },
        ],
      },
      {
        title: "Costa Rica General Info",
        sections: [
          {
            title: "Geography",
            content: [
              "Costa Rica, in Central America, is bordered by Nicaragua to the North and Panama to the South with the Pacific Ocean to the West and the Caribbean Sea to the East.",
              "Abolishing its army in 1948, it is the only Latin American country included in the list of the world's 22 older democracies. Costa Rica has consistently been among the top Latin American countries in the Human Development Index, ranked 62nd in the world in 2010, and is cited by the UNDP as one of the countries that has attained much higher human development than other countries at the same income levels. The country is ranked 3rd in the world, and 1st among the Americas, in terms of the 2010 Environmental Performance Index.",
              "In 2007 the Costa Rican government announced plans for Costa Rica to become the first carbon neutral country by 2021. According to the New Economics Foundation, Costa Rica ranks first in the Happy Planet Index and is the 'greenest' country in the world.",
              "The highest regions of Costa Rica are found in the center of the country while the lowlands, which are more extensive and flat, extend to the Caribbean Coast on the northeast and to the northwest on the Pacific side. The Pacific Coast is more dissected, forming many bays, capes, cliffs and inlets. The Costa Rican mountain ranges form an independent group, part of the Central American massif. Three of these ranges run roughly from northwest to southeast, with a fourth (The Central Range) crossing them at the widest part of the country, thus forming a huge cross. Two volcanic ranges dominate northwestern Costa Rica. The first is the Guanacaste Volcanic Chain with its Orosi, Miravalles, Tenorio, Rincón de la Vieja and Arenal volcanos. Arenal offers amazing views, especially breathtaking when is clear, while Rincón de la Vieja's activity keeps natural mud pots in its foothills, bubbling permanently. The second range is the Tilarán Volcanic Chain in the northwest, formed by the hills of Abangares, Aguacate and Catedral. In the transverse chain of the central highlands, Poás, Barva, Irazú and Turrialba Volcanos are more accessible. Finally, to the south, are Costa Rica's highest mountains, those of the non-volcanic Talamanca Range. Of these, Chirripó Mountain is the most impressive, being the highest mountain in the country, 3,821 meters above sea level.",
              "The Caribbean coastline stretches for 212 km in a generally north-south direction. It is divided into two distinct sections; the San Juan River on the Nicaraguan border to the city of Limón and that which extends from the city of Limón to the Sixaola River on the border with Panamá. The first section consists of a long stretch of coastline that separates a series of estuary lagoons and waterways, fed by numerous rivers, from the sea. These make up the famous Tortuguero Canals, over 100 km long. These navigable canals are the habitat of seven species of turtles.",
              "The Pacific Coast extends over 1,016 km from one border to the other and offers a wide variety of geological features. From Salinas Bay in the north to the tip of Burica Point in the south, the Pacific Beaches of Costa Rica offer an almost infinite number of beaches for tourist enjoyment.",
              "The Santa Elena, Nicoya and Osa Peninsulas are the principal ones on the Pacific side.",
              "Two ferries cross the Nicoya Gulf, shortening the route to the isolated beaches of the southern tip of the Nicoya Peninsula. The shoreline of the gulf has many bays and promontories and is dotted with small islands.",
              "The Osa Peninsula, covered by great tracts of virgin forest with the most extensive variety of native species to be found in the country, lies on the western side of the Golfo Dulce.",
            ],
          },
        ],
      },
    ],
    photos: [
      require("@/assets/trips/volunteeringFamilyAdventure/vfa1.jpg"),
      require("@/assets/trips/volunteeringFamilyAdventure/vfa2.jpg"),
      require("@/assets/trips/volunteeringFamilyAdventure/vfa3.jpg"),
      require("@/assets/trips/volunteeringFamilyAdventure/vfa4.jpg"),
      require("@/assets/trips/volunteeringFamilyAdventure/vfa5.jpg"),
      require("@/assets/trips/volunteeringFamilyAdventure/vfa6.jpg"),
    ],
  },
  volcanoesBeaches: {
    code: "volcanoesBeaches",
    name: "Volcanoes & Beaches",
    headerSrc: require("@/assets/trips/volcanoesBeaches/header.jpg"),
    overview: {
      length: "9 days",
      mapSrc: require("@/assets/trips/volcanoesBeaches/map.jpg"),
      sellText:
        "Explore two of the most famous volcanoes in Costa Rica and chill out at the warm waters of the Pacific coast.",
      overviewText: [
        "On this journey, you will visit two of the most important volcanoes located in the highlands of Costa Rica. Although the Arenal Volcano entered into a resting phase in 2010, it is still a stunning sight. Rincon de La Vieja Volcano offers you a great diversity of flora and fauna but also hot springs, fumaroles and many others exciting experiences.",
      ],
      highlights: [
        "Whitewater rafting or Safari Floating.",
        "Hiking around the magnificent Arenal Volcano",
        "Hot springs",
        "Rincon de la Vieja Volcano",
        "Beach time in Guanacaste",
      ],
      isPrivateItinerary: true,
      tripDates: [],
      note: "NOTE: All dates are guaranteed departures. We will open a new date with a minimum of 4 people.",
      priceSection: {
        primary: {
          price: "$1,005 USD",
          pp: "per person",
          singleSupp: "Single Supplement $310",
          doubleOccupancy: "(based in double occupancy)",
          validity: "",
        },
        peakSeason: {
          priceText: "PEAK SEASON",
          price: "$1,098 USD",
          pp: "per person",
          singleSupp: "Single Supplement $385",
          doubleOccupancy: "(based in double occupancy)",
          validity: "(Christmas & Easter)",
        },
        secondary: {
          price: "",
          pp: "",
          singleSupp: "",
          validity: "",
        },
      },
    },
    itinerary: [
      {
        day: "1",
        logos: ["arrival"],
        title: "Arrival in Costa Rica – Hotel in San Jose",
        content: [
          "A Coast to Coast representative will meet you at the Juan Santamaria International Airport in San Jose and give you vouchers and trip instructions then drive you to a cozy hotel in the capital.",
        ],
      },
      {
        day: "2",
        logos: ["rafting"],
        title: "Arenal Volcano - Safari floating or Whitewater Rafting",
        content: [
          "On the way to La Fortuna, under Arenal Volcano, choose one of these three options:",
          "- Rafting on the Toro or Sarapiqui River with technical and continuous rapids graded class III-IV that will challenge even our most experienced guests. Only for people older than 14y/o.",
          "- Rafting on the Balsa plus San Carlos River with rapids graded class 2-3. This river is a great option for families with kids older than 7y/o and beginners.",
          "- Safari float on the Peñas Blancas River. Silently paddle inflatable rafts through quiet waters with a naturalist to point out the variety of flora and fauna of this region. Sloths, monkeys, toucans, crocodiles, Jesus Christ Lizards, and turtles might cross your way, as well as a world of colorful tropical birds. Visit the farm of Don Pedro, a peek into the lifestyle of Costa Rica's small independent farmers and taste a snack made from the farm's production.",
          "The evening explore the town of La Fortuna and its surroundings – there are many restaurants around for you to choose. (B, L)",
        ],
      },
      {
        day: "3",
        logos: ["volcano", "trekking"],
        title: "Arenal Volcano - Hiking & Hot Springs",
        content: [
          "Free morning on your own to relax in your hotel or take any of the optional activities this area including horseback riding to La Fortuna’s Waterfall, ATV’s, canoeing, stand up paddling, mountain biking, waterfall rappelling (canyoning), canopy zip line tour, Aerial Tram, caving, hanging bridges, a visit to the Ecocentro Danaus or the Arenal Natura Park, Proyecto Asis Wildlife Rescue Center, visit a Maleku Indigenous villa, cooking class, Guaro Tour Party Between 3 and 4 in the afternoon be ready to leave your hotel with the tour company to hike with a naturalist guide on trails to spot a variety of diverse flora and fauna -- monkeys, toucans, wild turkeys, sloth, birds, lizards, iguanas, snakes, and interesting insects. The flora here is different because the volcano produces such a different type of soil. Arriving at the observation point in time for sunset means a fantastic view of this majestic volcano against the backdrop of the setting sun. You continue your way down to a natural Hot Springs for a relaxing bath and dinner. (B, D)",
        ],
      },
      {
        day: "4",
        logos: ["volcano"],
        title: "To Rincon de La Vieja Volcano",
        content: [
          "Your journey takes you to the Rincón de la Vieja National Park area (4.5 hours). This park surrounds two impressive volcanoes – the active Rincón de la Vieja and the dormant Santa María. The park is split into two sections, Las Pailas and Santa María. Las Pailas includes the active volcano, along with fumaroles, mud pots, steam vents, and a number of waterfalls. (B)",
        ],
      },
      {
        day: "5",
        logos: ["volcano"],
        title: "Rincon de La Vieja Volcano - Free day for optional activities",
        content: [
          "The day is free to relax at the pool of your hotel or explore the area taking one of the different activities this area offers: river tubing, horseback riding, waterfall rappelling, zip lining, mountain biking, hiking to waterfalls, hiking to the park, butterfly farm, serpentarium, archeology tour, among others. (B)",
        ],
      },
      {
        day: "6",
        logos: ["pacific-palm"],
        title: "To Gulf of Papagayo",
        content: [
          "After breakfast you head to one of the famous beaches in the north Pacific coast in the province of Guanacaste (1.5 hour). (B)",
        ],
      },
      {
        day: "7",
        logos: ["pacific-palm"],
        title: "Gulf of Papagayo - Free day on the beach",
        content: [
          "On your own to enjoy the beach, relax in your hotel and explore the surroundings. Optional activities: sailing, snorkeling, rafting, zip lining and all the best beach tours. (B)",
        ],
      },
      {
        day: "8",
        logos: ["pacific-palm"],
        title: "Back to San Jose",
        content: [
          "Enjoy your last morning at the beach before your ride back to San Jose, about 5 hours away. Visit some of the great night spots in San José. (B)",
        ],
      },
      {
        day: "9",
        logos: ["departure"],
        title: "Departure",
        content: [
          "A Coast to Coast representative will pick you up for your flight back home. (B)",
        ],
      },
    ],
    needToKnow: [
      {
        title: "General Trip Information",
        sections: [
          {
            title: "Is this trip for me?",
            content: [
              "We divide our trips in 3 different levels (low, medium and high) to give our participants an idea of the fitness level they should have for the trip. This trip is rated as Low",
              "Low: A trip designed for everyone! We ask that you have a healthy spirit of adventure and good general fitness. There are no specific requirements for whitewater rafting but you will fully participate in paddling.",
            ],
          },
          {
            title: "Daily routine",
            content: [
              "Our days usually start with fresh coffee and breakfast around 6:30. All packed and ready to go for our activity by 8:00. During the day you will have lots of time for pictures, water breaks and enjoying the scenery. The activities will be done at a comfortable pace for everyone, set by the guide. Around mid-day we will stop for lunch at a local restaurant or a picnic lunch made by the guides. The group will finish the day around 3:00 with plenty of time to rest. Dinner is usually served around 7:00 followed by a briefing for the next day.",
            ],
          },
          {
            title: "Price includes",
            content: [
              "Accommodation, transportation, airport transfers, meals where indicated, group equipment (bikes, rafts, camping gear, etc.), support vehicle and experienced, bilingual & fun guides throughout the trip.",
            ],
          },
          {
            title: "Guides Qualifications",
            content: [
              "All Coast to Coast Adventures’ guides have First AID, CPR and Swiftwater Rescue and Head Guides have Wilderness First Responder.",
            ],
          },
          {
            title: "Group size",
            content: ["Average between 6 and 12."],
          },
          {
            title: "Vaccinations & Protection",
            content: [
              "Visa and vaccination requirements are subject to change and should be confirmed before departure. Consult your Doctor for latest advice on different prophylaxis available against malaria.",
            ],
          },
          {
            title: "Laundry",
            content: [
              "Having your cloth clean and dry while you travel is always a challenge. Laundry services are offered by some of our hotels for a charge. There will be times when you may want to do your own laundry so we suggest you bring non-polluting/biodegradable soap.",
            ],
          },
          {
            title: "Travel Insurance",
            content: [
              "CtoC Adventures requires all customers to have valid Travel Insurance.",
            ],
          },
        ],
      },
      {
        title: "Accommodations, Meals and Transportation",
        sections: [
          {
            title: "Accommodations",
            content: ["8 nights in Hotels"],
          },
          {
            title: "Transportation",
            content: [
              "All transportation according to the itinerary is included, starting with the transfer in, along the trip with transportation from one area to the other, as well with transportation during the activities. Finishing with the transfer out.",
            ],
          },
          {
            title: "Meals",
            content: [
              "Eating is one of the wonderful experiences when travelling in Costa Rica. Included meals are a combination of restaurants, local food and delicious meals prepared by the tour guides.",
            ],
          },
          {
            title: "Meals Included",
            content: ["Breakfasts 8", "Lunches 1", "Dinners 1"],
          },
        ],
      },
      {
        title: "Gear / Kit Needed",
        sections: [
          {
            title: "Luggage Carrier",
            content: [
              "Duffle Bag",
              "Daypack (approx. 20-35 L)",
              "Daypack rain cover",
            ],
          },
          {
            title: "Upper Body",
            content: [
              "Shirts (poly-pro outer wear for quick drying)",
              "Short sleeve and light colored long sleeve for sun protection)",
              "Lightweight rain jacket (Gortex, nylon or similar)",
              "Medium weight wool or fleece over-sweater",
              "Lightweight thermal underwear top (polypro/wool)",
            ],
          },
          {
            title: "Lower Body",
            content: [
              "Lightweight comfortable pants",
              "Shorts (quick dry)",
              "Lightweight rain pants",
            ],
          },
          {
            title: "Head",
            content: [
              "Sun/rain hat with brim (preferably 360˚ brim)",
              "Bandana",
            ],
          },
          {
            title: "Footwear",
            content: [
              "Lightweight adventure shoes or hiking boots",
              "Sandals with SNUG straps (Teva type)",
              "Lightweight fast drying hiking socks",
            ],
          },
          {
            title: "Personal Gear",
            content: [
              "Sunglasses (UVA and UVB resistant)",
              "Keeper straps for sunglasses",
              "1 headlamp (long lasting LED bulbs are the best option)",
              "Extra batteries (for headlamp, camera, etc)",
              "Additional small flashlight",
              "2-3 one quart, durable water bottles",
              "Prescription glasses or contacts (bring an extra pair of glasses)",
              "1 copy of your passport",
              "Insect repellent",
              "Waterproof sunscreen 30+ SPF",
              "Gallon & quart size Ziploc bags",
              "Towel",
            ],
          },
          {
            title: "Optional Gear",
            content: [
              "Dry bag",
              "Camera w/ waterproof camera bag",
              "Deck of cards",
              "Binoculars",
              "Sarongs (works as a towel, or quick cover-up)",
              "Writing materials (pen, pencil, notebook…)",
              "USB memory card (to share pictures with other participants)",
            ],
          },
          {
            title: "Note",
            content: [
              "No sleeping gear needed for overnight stays at El Nido del Tigre and Finca Pacuarito",
            ],
          },
        ],
      },
      {
        title: "Money Related",
        sections: [
          {
            title: "Money Exchange",
            content: [
              "A combination of US dollars and credit/debit cards is ideal, it’s best to bring notes in good condition and lower than 100USD. Travelers cheques are not a good idea. Cards are widely accepted but little stores or street sellers may only take local cash. There are ATMS along the way. Check with your bank to make sure your card will work internationally.",
            ],
          },
          {
            title: "Spending Money",
            content: [
              "How much you need will depend on your spending habits. You will need to pay for some meals, drinks, souvenirs and tipping. Here are a few things to keep in mind when you are deciding on the amount of spending money to take on your trip.",
            ],
          },
          {
            title: "Departure Tax",
            content: [
              "The Departure Tax in Costa Rica is $29. This needs to be paid at the airport using US or local cash or any major credit card. Pay this tax before going to the airline counter. Check with your airline if this fee is already included on your flight ticket.",
            ],
          },
          {
            title: "Tipping",
            content: [
              "It is customary in Latin America to tip service providers such as waiters, at approximately 10%, depending on the service. Also if you felt your local guides and drivers did an outstanding job, tipping is appreciated.",
            ],
          },
          {
            title: "Meal Costs",
            content: ["Lunch: $10 to $12", "Dinner: $12 to $20"],
          },
        ],
      },
      {
        title: "Costa Rica General Info",
        sections: [
          {
            title: "General Information",
            content: [
              "Costa Rica, in Central America, is bordered by Nicaragua to the North and Panama to the South with the Pacific Ocean to the West and the Caribbean Sea to the East.",
              "Abolishing its army in 1948, it is the only Latin American country included in the list of the world's 22 older democracies. Costa Rica has consistently been among the top Latin American countries in the Human Development Index, ranked 62nd in the world in 2010, and is cited by the UNDP as one of the countries that has attained much higher human development than other countries at the same income levels. The country is ranked 3rd in the world, and 1st among the Americas, in terms of the 2010 Environmental Performance Index.",
              "In 2007 the Costa Rican government announced plans for Costa Rica to become the first carbon neutral country by 2021. According to the New Economics Foundation, Costa Rica ranks first in the Happy Planet Index and is the 'greenest' country in the world.",
              "The highest regions of Costa Rica are found in the center of the country while the lowlands, which are more extensive and flat, extend to the Caribbean Coast on the northeast and to the northwest on the Pacific side. The Pacific Coast is more dissected, forming many bays, capes, cliffs and inlets. The Costa Rican mountain ranges form an independent group, part of the Central American massif. Three of these ranges run roughly from northwest to southeast, with a fourth (The Central Range) crossing them at the widest part of the country, thus forming a huge cross. Two volcanic ranges dominate northwestern Costa Rica. The first is the Guanacaste Volcanic Chain with its Orosi, Miravalles, Tenorio, Rincón de la Vieja and Arenal volcanos. Arenal offers amazing views, especially breathtaking when is clear, while Rincón de la Vieja's activity keeps natural mud pots in its foothills, bubbling permanently. The second range is the Tilarán Volcanic Chain in the northwest, formed by the hills of Abangares, Aguacate and Catedral. In the transverse chain of the central highlands, Poás, Barva, Irazú and Turrialba Volcanos are more accessible. Finally, to the south, are Costa Rica's highest mountains, those of the non-volcanic Talamanca Range. Of these, Chirripó Mountain is the most impressive, being the highest mountain in the country, 3,821 meters above sea level.",
              "The Caribbean coastline stretches for 212 km in a generally north-south direction. It is divided into two distinct sections; the San Juan River on the Nicaraguan border to the city of Limón and that which extends from the city of Limón to the Sixaola River on the border with Panamá. The first section consists of a long stretch of coastline that separates a series of estuary lagoons and waterways, fed by numerous rivers, from the sea. These make up the famous Tortuguero Canals, over 100 km long. These navigable canals are the habitat of seven species of turtles.",
              "The Pacific Coast extends over 1,016 km from one border to the other and offers a wide variety of geological features. From Salinas Bay in the north to the tip of Burica Point in the south, the Pacific Beaches of Costa Rica offer an almost infinite number of beaches for tourist enjoyment.",
              "The Santa Elena, Nicoya and Osa Peninsulas are the principal ones on the Pacific side.",
              "Two ferries cross the Nicoya Gulf, shortening the route to the isolated beaches of the southern tip of the Nicoya Peninsula. The shoreline of the gulf has many bays and promontories and is dotted with small islands.",
              "The Osa Peninsula, covered by great tracts of virgin forest with the most extensive variety of native species to be found in the country, lies on the western side of the Golfo Dulce.",
            ],
          },
        ],
      },
    ],
    photos: [
      require("@/assets/trips/volcanoesBeaches/vb1.jpg"),
      require("@/assets/trips/volcanoesBeaches/vb2.jpg"),
      require("@/assets/trips/volcanoesBeaches/vb3.jpg"),
      require("@/assets/trips/volcanoesBeaches/vb4.jpg"),
      require("@/assets/trips/volcanoesBeaches/vb5.jpg"),
      require("@/assets/trips/volcanoesBeaches/vb6.jpg"),
      require("@/assets/trips/volcanoesBeaches/vb7.jpg"),
    ],
  },
  adventuresInNature: {
    code: "adventuresInNature",
    name: "Adventures In Nature",
    headerSrc: require("@/assets/trips/adventuresInNature/header.jpg"),
    overview: {
      length: "9 days",
      mapSrc: require("@/assets/trips/adventuresInNature/map.jpg"),
      sellText:
        "Two of Costa Rica's main attractions, Arenal volcano and the cloud forest of Monteverde, then the turquoise green water and fiery red and gold sunsets of Dominical. For natural adventure at its best, this trip is exactly for you.",
      overviewText: [
        "Explore the majestic Arenal volcano and take a guided hike around the base. Ascend to Monteverde Cloud Forest, the most famous cloud forest in the world and important for its biodiversity, conservation contributions and scientific research facilities.",
        "Then beach time at the laid back surfer's paradise of Dominical, surrounded by plantations, estuaries, mangroves and marshes. There's all levels of breaks, for beginners (with great instructors)to world class competitors. Waves can reach over 10 feet.",
      ],
      highlights: [
        "Hiking around the magnificent Arenal Volcano",
        "Hot springs",
        "Night hike in the cloud forest",
        "Canopy tour",
        "Beach time in Dominical",
      ],
      isPrivateItinerary: true,
      tripDates: [],
      note: "NOTE: All dates are guaranteed departures. We will open a new date with a minimum of 4 people.",
      priceSection: {
        primary: {
          price: "$990 USD",
          pp: "per person",
          singleSupp: "Single Supplement $305",
          doubleOccupancy: "(based in double occupancy)",
          validity: "",
        },
        peakSeason: {
          priceText: "PEAK SEASON",
          price: "$1,095 USD",
          pp: "per person",
          singleSupp: "Single Supplement $380",
          doubleOccupancy: "(based in double occupancy)",
          validity: "(Christmas & Easter)",
        },
        secondary: {
          price: "",
          pp: "",
          singleSupp: "",
          validity: "",
        },
      },
    },
    itinerary: [
      {
        day: "1",
        logos: ["arrival"],
        title: "Arrival in Costa Rica – Hotel in San Jose",
        content: [
          "A Coast to Coast representative will meet you at the Juan Santamaria International Airport in San Jose and give you vouchers and trip instructions then drive you to a cozy hotel in the capital.",
        ],
      },
      {
        day: "2",
        logos: ["volcano", "trekking"],
        title: "To Arenal Volcano - Hiking & Hot Springs",
        content: [
          "Today after breakfast you will be transferred to La Fortuna, the closest town to the Arenal volcano (3.5 hours). Between 3 and 4 in the afternoon be ready to leave your hotel with the tour company to hike with a naturalist guide on trails to spot a variety of diverse flora and fauna -- monkeys, toucans, wild turkeys, sloth, birds, lizards, iguanas, snakes, and interesting insects. The flora here is different because the volcano produces such a different type of soil. Arriving at the observation point in time for sunset means a fantastic view of this majestic volcano against the backdrop of the setting sun. You continue your way down to a natural Hot Springs for a relaxing bath and dinner. (B, D)",
        ],
      },
      {
        day: "3",
        logos: ["volcano"],
        title: "Arenal Volcano - Free day",
        content: [
          "Free day on your own to relax in your hotel or take any of the optional activities this area including horseback riding to La Fortuna Waterfall, ATV’s, canoeing, stand up paddling, mountain biking, waterfall rappelling (canyoning), canopy zip line tour, Aerial Tram, caving, hanging bridges, a visit to the Ecocentro Danaus or the Arenal Natura Park, Proyecto Asis Wildlife Rescue Center, visit a Maleku Indigenous villa, cooking class, Guaro Tour Party, Caño Negro Wildlife Refuge boat trip, Rio Celeste hike, canoeing, caving, hanging bridges, amongst others. (B)",
        ],
      },
      {
        day: "4",
        logos: ["trekking"],
        title: "To Monteverde Cloud Forest - Twilight Hike",
        content: [
          "We are on the move from La Fortuna to the cloud forest of Monteverde. We cross Lake Arenal to Rio Chiquito by boat, then by car to Monteverde (3 hours). In the afternoon your tour begins just before sunset and finishes in total darkness to observe both diurnal and nocturnal wildlife. (B)",
        ],
      },
      {
        day: "5",
        logos: ["canopy"],
        title: "Monteverde - Zip Lining, Hanging Bridges or Coffee Tour",
        content: [
          "This morning choose one of these three activities:",
          "Zip lining: you will be flying along 15 cables and 18 platforms built in trees and ending with an (optional) Tarzan Swing. Harnesses and adrenaline in the jungle.... but safe enough for even 10 year olds not afraid of heights or zooming.",
          "Hanging bridges: when your objective is seeing minute detail in the cloud forest, the hanging bridges gives you time to stop and focus in on a small plant or hidden animal. A naturalist guide specialist in Monteverde flora and fauna accompanies you to explain details on habitat, biodiversity and answer your questions.",
          "Coffee tour: learn about our Golden Bean and the complete process from seed to the coffee cup. Your guide will explain germination beds, coffee plantations, the depulping machine, sun dryers, peeling methods, roasters and packing. Picking coffee in Costa Rica has been a tradition for families for generations and if the season is right you can join in filling your own canasta (basket) with the bright red cherries.",
          "You have the afternoon and evening free. Optional activities include hike in the Monteverde or Santa Elena cloud forest, horseback ride to an ecological farm, visit the serpentarium and butterfly farm. (B)",
        ],
      },
      {
        day: "6",
        logos: ["pacific-palm"],
        title: "To Dominical",
        content: [
          "After breakfast we take you to the beach town of Dominical on the Pacific coast of the country (5.5 hours). Relax, jump in for a swim, or rent a surfboard. (B)",
        ],
      },
      {
        day: "7",
        logos: ["pacific-palm"],
        title: "Dominical - Free day on the beach",
        content: [
          "Free time on your own. Because Dominical is close to the Osa Peninsula, we recommend you take one of the optional tours that we will be happy to arrange for you in advance including Corcovado National Park hike, snorkeling at Isla del Caño or at Marino Ballena National Park, sea kayaking, whales and dolphins encounter, hiking, horseback riding. (B)",
        ],
      },
      {
        day: "8",
        logos: ["pacific-palm"],
        title: "Back to San Jose",
        content: [
          "Your final morning on the beach. After lunch we head back to San José, about 4 hours. (B)",
        ],
      },
      {
        day: "9",
        logos: ["departure"],
        title: "Departure",
        content: [
          "A Coast to Coast representative will pick you up at your hotel for your homeward flight. Hard to leave, but you'll be back! (B)",
        ],
      },
    ],
    needToKnow: [
      {
        title: "General Trip Information",
        sections: [
          {
            title: "Is this trip for me?",
            content: [
              "We divide our trips in 3 different levels (low, medium and high) to give our participants an idea of the fitness level they should have for the trip. This trip is rated as Low",
              "Low: A trip designed for everyone! We ask that you have a healthy spirit of adventure and good general fitness. There are no specific requirements for whitewater rafting but you will fully participate in paddling.",
            ],
          },
          {
            title: "Daily routine",
            content: [
              "Our days usually start with fresh coffee and breakfast around 6:30. All packed and ready to go for our activity by 8:00. During the day you will have lots of time for pictures, water breaks and enjoying the scenery. The activities will be done at a comfortable pace for everyone, set by the guide. Around mid-day we will stop for lunch at a local restaurant or a picnic lunch made by the guides. The group will finish the day around 3:00 with plenty of time to rest. Dinner is usually served around 7:00 followed by a briefing for the next day.",
            ],
          },
          {
            title: "Price includes",
            content: [
              "Accommodation, transportation, airport transfers, meals where indicated, group equipment (bikes, rafts, camping gear, etc.), support vehicle and experienced, bilingual & fun guides throughout the trip.",
            ],
          },
          {
            title: "Guides Qualifications",
            content: [
              "All Coast to Coast Adventures’ guides have First AID, CPR and Swiftwater Rescue and Head Guides have Wilderness First Responder.",
            ],
          },
          {
            title: "Group size",
            content: ["Average between 6 and 12."],
          },
          {
            title: "Vaccinations & Protection",
            content: [
              "Visa and vaccination requirements are subject to change and should be confirmed before departure. Consult your Doctor for latest advice on different prophylaxis available against malaria.",
            ],
          },
          {
            title: "Laundry",
            content: [
              "Having your cloth clean and dry while you travel is always a challenge. Laundry services are offered by some of our hotels for a charge. There will be times when you may want to do your own laundry so we suggest you bring non-polluting/biodegradable soap.",
            ],
          },
          {
            title: "Travel Insurance",
            content: [
              "CtoC Adventures requires all customers to have valid Travel Insurance.",
            ],
          },
        ],
      },
      {
        title: "Accommodations, Meals and Transportation",
        sections: [
          {
            title: "Accommodations",
            content: ["8 nights in Hotels"],
          },
          {
            title: "Transportation",
            content: [
              "All transportation according to the itinerary is included, starting with the transfer in, along the trip with transportation from one area to the other, as well with transportation during the activities. Finishing with the transfer out.",
            ],
          },
          {
            title: "Meals",
            content: [
              "Eating is one of the wonderful experiences when travelling in Costa Rica. Included meals are a combination of restaurants, local food and delicious meals prepared by the tour guides.",
            ],
          },
          {
            title: "Meals Included",
            content: ["Breakfasts 8", "Lunches 0", "Dinners 1"],
          },
        ],
      },
      {
        title: "Gear / Kit Needed",
        sections: [
          {
            title: "Luggage Carrier",
            content: [
              "Duffle Bag",
              "Daypack (approx. 20-35 L)",
              "Daypack rain cover",
            ],
          },
          {
            title: "Upper Body",
            content: [
              "Shirts (poly-pro outer wear (for quick drying)",
              "Short sleeve and light colored long sleeve for sun protection)",
              "Lightweight rain jacket (Gortex, nylon or similar)",
              "Medium weight wool or fleece over-sweater",
              "Lightweight thermal underwear top (polypro/wool)",
            ],
          },
          {
            title: "Lower Body",
            content: [
              "Lightweight comfortable pants",
              "Shorts (quick dry)",
              "Lightweight rain pants",
            ],
          },
          {
            title: "Head",
            content: [
              "Sun/rain hat with brim (preferably 360˚ brim)",
              "Bandana",
            ],
          },
          {
            title: "Footwear",
            content: [
              "Lightweight adventure shoes or hiking boots",
              "Sandals with SNUG straps (Teva type)",
              "Lightweight fast drying hiking socks",
            ],
          },
          {
            title: "Personal Gear",
            content: [
              "Sunglasses (UVA and UVB resistant)",
              "Keeper straps for sunglasses",
              "1 headlamp (long lasting LED bulbs are the best option)",
              "Extra batteries (for headlamp, camera, etc)",
              "Additional small flashlight",
              "2-3 one quart, durable water bottles",
              "Prescription glasses or contacts (bring an extra pair of glasses)",
              "1 copy of your passport",
              "Insect repellent",
              "Waterproof sunscreen 30+ SPF",
              "Gallon & quart size Ziploc bags",
              "Towel",
            ],
          },
          {
            title: "Optional Gear",
            content: [
              "Dry bag",
              "Camera w/ waterproof camera bag",
              "Deck of cards",
              "Binoculars",
              "Sarongs (works as a towel, or quick cover-up)",
              "Writing materials (pen, pencil, notebook…)",
              "USB memory card (to share pictures with other participants)",
            ],
          },
          {
            title: "Note",
            content: [
              "No sleeping gear needed for overnight stays at El Nido del Tigre and Finca Pacuarito",
            ],
          },
        ],
      },
      {
        title: "Money Related",
        sections: [
          {
            title: "Money Exchange",
            content: [
              "A combination of US dollars and credit/debit cards is ideal, it’s best to bring notes in good condition and lower than 100USD. Travelers cheques are not a good idea. Cards are widely accepted but little stores or street sellers may only take local cash. There are ATMS along the way. Check with your bank to make sure your card will work internationally.",
            ],
          },
          {
            title: "Spending Money",
            content: [
              "How much you need will depend on your spending habits. You will need to pay for some meals, drinks, souvenirs and tipping. Here are a few things to keep in mind when you are deciding on the amount of spending money to take on your trip.",
            ],
          },
          {
            title: "Departure Tax",
            content: [
              "The Departure Tax in Costa Rica is $29. This needs to be paid at the airport using US or local cash or any major credit card. Pay this tax before going to the airline counter. Check with your airline if this fee is already included on your flight ticket.",
            ],
          },
          {
            title: "Tipping",
            content: [
              "It is customary in Latin America to tip service providers such as waiters, at approximately 10%, depending on the service. Also if you felt your local guides and drivers did an outstanding job, tipping is appreciated.",
            ],
          },
          {
            title: "Meal Costs",
            content: ["Lunch: $10 to $12", "Dinner: $12 to $20"],
          },
        ],
      },
      {
        title: "Costa Rica General Info",
        sections: [
          {
            title: "Overview",
            content: [
              "Costa Rica, in Central America, is bordered by Nicaragua to the North and Panama to the South with the Pacific Ocean to the West and the Caribbean Sea to the East.",
              "Abolishing its army in 1948, it is the only Latin American country included in the list of the world's 22 older democracies. Costa Rica has consistently been among the top Latin American countries in the Human Development Index, ranked 62nd in the world in 2010, and is cited by the UNDP as one of the countries that has attained much higher human development than other countries at the same income levels. The country is ranked 3rd in the world, and 1st among the Americas, in terms of the 2010 Environmental Performance Index.",
              "In 2007 the Costa Rican government announced plans for Costa Rica to become the first carbon neutral country by 2021. According to the New Economics Foundation, Costa Rica ranks first in the Happy Planet Index and is the 'greenest' country in the world.",
              "The highest regions of Costa Rica are found in the center of the country while the lowlands, which are more extensive and flat, extend to the Caribbean Coast on the northeast and to the northwest on the Pacific side. The Pacific Coast is more dissected, forming many bays, capes, cliffs and inlets. The Costa Rican mountain ranges form an independent group, part of the Central American massif. Three of these ranges run roughly from northwest to southeast, with a fourth (The Central Range) crossing them at the widest part of the country, thus forming a huge cross. Two volcanic ranges dominate northwestern Costa Rica. The first is the Guanacaste Volcanic Chain with its Orosi, Miravalles, Tenorio, Rincón de la Vieja and Arenal volcanos. Arenal offers amazing views, especially breathtaking when is clear, while Rincón de la Vieja's activity keeps natural mud pots in its foothills, bubbling permanently. The second range is the Tilarán Volcanic Chain in the northwest, formed by the hills of Abangares, Aguacate and Catedral. In the transverse chain of the central highlands, Poás, Barva, Irazú and Turrialba Volcanos are more accessible. Finally, to the south, are Costa Rica's highest mountains, those of the non-volcanic Talamanca Range. Of these, Chirripó Mountain is the most impressive, being the highest mountain in the country, 3,821 meters above sea level.",
              "The Caribbean coastline stretches for 212 km in a generally north-south direction. It is divided into two distinct sections; the San Juan River on the Nicaraguan border to the city of Limón and that which extends from the city of Limón to the Sixaola River on the border with Panamá. The first section consists of a long stretch of coastline that separates a series of estuary lagoons and waterways, fed by numerous rivers, from the sea. These make up the famous Tortuguero Canals, over 100 km long. These navigable canals are the habitat of seven species of turtles.",
              "The Pacific Coast extends over 1,016 km from one border to the other and offers a wide variety of geological features. From Salinas Bay in the north to the tip of Burica Point in the south, the Pacific Beaches of Costa Rica offer an almost infinite number of beaches for tourist enjoyment.",
              "The Santa Elena, Nicoya and Osa Peninsulas are the principal ones on the Pacific side.",
              "Two ferries cross the Nicoya Gulf, shortening the route to the isolated beaches of the southern tip of the Nicoya Peninsula. The shoreline of the gulf has many bays and promontories and is dotted with small islands.",
              "The Osa Peninsula, covered by great tracts of virgin forest with the most extensive variety of native species to be found in the country, lies on the western side of the Golfo Dulce.",
            ],
          },
        ],
      },
    ],
    photos: [
      require("@/assets/trips/adventuresInNature/ain1.jpg"),
      require("@/assets/trips/adventuresInNature/ain2.jpg"),
      require("@/assets/trips/adventuresInNature/ain3.jpg"),
      require("@/assets/trips/adventuresInNature/ain4.jpg"),
      require("@/assets/trips/adventuresInNature/ain5.jpg"),
      require("@/assets/trips/adventuresInNature/ain6.jpg"),
      require("@/assets/trips/adventuresInNature/ain7.jpg"),
    ],
  },
};

const allTrips = [
  trips.coastToCoast,
  trips.costaricanAdventure,
  trips.volcanoesRiversBeaches,
  trips.volcanoToWaves,
  trips.centralAmericaMultisport,
  trips.activeFamilyAdventure,
  trips.volunteeringFamilyAdventure,
  trips.volcanoesBeaches,
  trips.adventuresInNature,
];

const joinAnAdventure = [
  trips.coastToCoast,
  trips.costaricanAdventure,
  trips.volcanoesRiversBeaches,
  trips.centralAmericaMultisport,
];

const familyTrips = [
  trips.activeFamilyAdventure,
  trips.volunteeringFamilyAdventure,
];

const tripsWithPromotions = allTrips.filter(
  (trip) => trip.overview.promotionText != undefined
);

const data = {
  allTrips,
  joinAnAdventure,
  familyTrips,
  trips,
  tripsWithPromotions,
};

export default data;
